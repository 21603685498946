/* eslint-disable @typescript-eslint/no-floating-promises */
import { Button as AntdButton, Input, message, Modal } from 'antd'
import isUrl from 'is-url'

import { Dispatch, SetStateAction } from 'react'
import { useSlateStatic } from 'slate-react'
import { unwrapLink, wrapLink } from './withInlines'
export const InsertLinkModal = ({
  isModalVisible,
  setIsModalVisible,
  httpLink,
  setHttpLink
}: {
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  httpLink: string
  setHttpLink: Dispatch<SetStateAction<string>>
}): JSX.Element => {
  const editor = useSlateStatic()

  const handleOk = (): void => {
    if (httpLink.length === 0) {
      unwrapLink(editor)
    } else if (isUrl(httpLink)) {
      wrapLink(editor, httpLink)
    } else {
      message.error('Not a valid link.')
    }
    setIsModalVisible(false)
  }
  const handleCancel = (): void => {
    setIsModalVisible(false)
  }

  return (
    <Modal
      title='Insert or Edit Link'
      visible={isModalVisible}
      footer={[
        <AntdButton key='okModal' type='primary' onClick={handleOk}>
          Ok
        </AntdButton>,
        <AntdButton key='cancelModal' type='primary' onClick={handleCancel}>
          Cancel
        </AntdButton>
      ]}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <>
        <div>
          <Input
            value={httpLink}
            onChange={(e) => setHttpLink(e.target.value)}
          />
          <p>Note: You can remove existing links by leaving the box empty.</p>
        </div>
      </>
    </Modal>
  )
}
