import { css } from '@emotion/css'
import { Descendant } from 'slate'
import { useSelected } from 'slate-react'

export interface LinkElement {
  type: 'link'
  url: string
  children: Descendant[]
}

export const LinkComponent = ({
  attributes,
  children,
  element
}: {
  attributes: any
  children: Descendant[]
  element: LinkElement
}): JSX.Element => {
  const selected = useSelected()
  return (
    <a
      {...attributes}
      href={element.url}
      className={
        selected
          ? css`
              box-shadow: 0 0 0 3px #ddd;
            `
          : ''
      }
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  )
}

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = (): JSX.Element => (
  <span
    contentEditable={false}
    className={css`
      font-size: 0;
    `}
  >
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)
