import { InboxOutlined } from '@ant-design/icons'
import { Button as AntdButton, message, Modal, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import { Dispatch, SetStateAction } from 'react'
import { BaseEditor, Transforms } from 'slate'
import { HistoryEditor } from 'slate-history'
import { ReactEditor, useSlateStatic } from 'slate-react'
import { resolveEndpointUrl } from '~/utils/location/api/utils'
import {
  fileDownloadEndpoint,
  fileUploadEndpoint,
  FileUploadResponse
} from '~shared/api'
import { ImageElement } from '../types/slate.custom.types'

const { Dragger } = Upload
export const InsertImageModal = ({
  isModalVisible,
  setIsModalVisible
}: {
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
}): JSX.Element => {
  const editor = useSlateStatic()
  const handleCancel = (): void => {
    setIsModalVisible(false)
  }
  return (
    <Modal
      title='Insert Image'
      visible={isModalVisible}
      footer={[
        <AntdButton key='okModal' type='primary' onClick={handleCancel}>
          Cancel
        </AntdButton>
      ]}
      onCancel={handleCancel}
    >
      <Dragger
        name={fileUploadEndpoint.fileFormName}
        multiple
        accept='image/png,image/jpeg,image/gif'
        action={resolveEndpointUrl(fileUploadEndpoint, {})}
        onChange={(info: UploadChangeParam<UploadFile<FileUploadResponse>>) => {
          const { status } = info.file
          const uploadResponse = info.file.response
          // console.log(status)
          // console.log(uploadResponse)
          if (status === 'done' && typeof uploadResponse?.id === 'string') {
            message
              .success(`${info.file.name} file uploaded successfully.`)
              .then(() => {}, console.error)
            insertImage(
              editor,
              resolveEndpointUrl(fileDownloadEndpoint, {
                requestParams: { id: uploadResponse.id }
              })
            )
            setIsModalVisible(false)
          } else if (status !== 'uploading') {
            message
              .error(`${info.file.name} file upload failed.`)
              .then(() => {}, console.error)
          }
        }}
        onDrop={(e) => {
          console.log('Dropped files', e.dataTransfer.files)
        }}
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p className='ant-upload-text'>
          Click or drag file to this area to upload
        </p>
        <p className='ant-upload-hint'>Supported format: jpg, png</p>
      </Dragger>
    </Modal>
  )
}

export const insertImage = (
  editor: BaseEditor & ReactEditor & HistoryEditor,
  url: string | ArrayBuffer | null
): void => {
  const text = { text: '' }
  const image: ImageElement = {
    type: 'image',
    link: '',
    url: url as string,
    size: 100,
    children: [text]
  }
  Transforms.insertNodes(editor, image)
}
