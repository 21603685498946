import moment from 'moment-timezone'
import { SermonAddOrEditOne } from '~/components/SermonAddOrEditOne'
import { useDynamicContent } from '~/context'
const tz = moment.tz.guess()
export function AddSermon (): JSX.Element {
  const { speakers, sermonTypes } = useDynamicContent()
  // console.log(moment.tz(tz))
  return (
    <section>
      <SermonAddOrEditOne
        {...{
          title: '',
          speaker: speakers[0]?.name ?? '',
          type_of_sermon: sermonTypes[0]?.name ?? '',
          bible_source: '',
          verses: '',
          verse_of_the_week: '',
          edit: false,
          published: false,
          date_of_sermon: moment.tz(tz),
          speakers: speakers.map((s) => s.name),
          types_of_sermons: sermonTypes.map((s) => s.name)
        }}
      />
    </section>
  )
}
