/* eslint-disable @typescript-eslint/no-extra-semi */
import { PlusOutlined } from '@ant-design/icons'
import { Divider, Input, Select, Space, Typography } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React, { useEffect, useState } from 'react'

export interface AppendableSelectProps extends SelectProps<any> {
  initialOptions?: string[] | undefined
  noNew?: boolean
}

export function AppendableSelect ({
  onChange,
  initialOptions,
  noNew,
  ...props
}: AppendableSelectProps): JSX.Element {
  const [name, setName] = useState('')
  let index = 0

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value)
  }

  const [options, setOptions] = useState<string[] | undefined>(initialOptions)
  useEffect(() => {
    setOptions(initialOptions)
  }, [initialOptions])

  const addOptions = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    setOptions([...(options ?? []), name ?? `New item ${index++}`])
    setName('')
  }
  return (
    <Select
      {...props}
      onChange={(value, option) => {
        if (onChange != null) {
          if (value === undefined) onChange(null, option)
          else onChange(value, option)
        }
      }}
      dropdownRender={(menu) => {
        if (noNew ?? false) {
          return <>{menu}</>
        } else {
          return (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <Space align='center' style={{ padding: '0 8px 4px' }}>
                <Input
                  placeholder='Please enter item'
                  value={name}
                  onChange={onNameChange}
                />
                <Typography.Link
                  onClick={addOptions}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <PlusOutlined /> Add item
                </Typography.Link>
              </Space>
            </>
          )
        }
      }}
    >
      {options?.map((item) => (
        <Select.Option key={item} value={item}>
          {item}
        </Select.Option>
      ))}
    </Select>
  )
}
