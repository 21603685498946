import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { getLoginStatus, login, logout } from '~/utils/location/api/endpoints'
import { LoginStatus } from '~shared/api'
import { isFalsy, runAsync } from '~shared/utils'
import { useGoogleSignInToken } from './GoogleApiClient'

interface LoginContextState extends LoginStatus {
  fetching: boolean
  logout: () => void
}

const LoginStatusContext = createContext<null | LoginContextState>(null)

export const LoginProvider = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {
  const loginToken = useGoogleSignInToken()

  const [loginStatus, setLoginStatus] = useState<LoginContextState>({
    loggedIn: false,
    isAdmin: false,
    verified: false,
    fetching: true,
    loginId: '',
    logout: () => {
      runAsync(async () => {
        const loginStatus = await logout({})

        setLoginStatus((oldLoginStatus) => ({
          ...oldLoginStatus,
          ...loginStatus,
          fetching: false
        }))
      })
    }
  })

  useEffect(() => {
    let mounted = true
    runAsync(async () => {
      const loginStatus = await getLoginStatus({})

      if (!mounted) return

      setLoginStatus((oldLoginStatus) => ({
        ...oldLoginStatus,
        ...loginStatus,
        fetching: false
      }))
    })

    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    if (isFalsy(loginToken)) return

    let mounted = true
    runAsync(async () => {
      const loginStatus = await login({
        requestBody: {
          token: loginToken
        }
      })

      if (!mounted) return

      setLoginStatus((oldLoginStatus) => ({
        ...oldLoginStatus,
        ...loginStatus,
        fetching: false
      }))
    })

    return () => {
      mounted = false
    }
  }, [loginToken])

  return (
    <LoginStatusContext.Provider value={loginStatus}>
      {children}
    </LoginStatusContext.Provider>
  )
}

/**
 * Gets the current login token. Returns an empty string if the user is not logged in.
 */
export const useLoginStatus = (): LoginContextState => {
  const status = useContext(LoginStatusContext)
  if (status !== null) return status

  console.warn(
    '[useLoginStatus]',
    'WARNING: useLoginStatus is not being used with LoginProvider'
  )
  return {
    isAdmin: false,
    loggedIn: false,
    verified: false,
    fetching: false,
    loginId: '',
    logout: () => {}
  }
}
