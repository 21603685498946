import { Spin } from 'antd'
import { useEffect } from 'react'
import { Redirect, useLocation } from 'react-router'
import { GoogleSignInButton } from '~/components/GoogleSignInButton'
import { useGoogleApiStatus, useLoginStatus } from '~/context'
import { useLanguange } from '~/utils/lang'

export function Login (): JSX.Element {
  const currentLanguage = useLanguange()
  const { loggedIn, fetching } = useLoginStatus()
  const googleApiReady = useGoogleApiStatus()
  const location = useLocation<{ previousUrl?: string }>()

  useEffect(() => {
    if (googleApiReady && !loggedIn && !fetching) {
      window.google.accounts.id.prompt()
    }
  }, [fetching, googleApiReady, loggedIn])

  if (loggedIn) {
    console.log('You are already logged in, redirecting to home page')
    return (
      <Redirect
        to={
          location.state?.previousUrl ?? `/${currentLanguage.currentLanguage}/`
        }
      />
    )
  }

  return (
    <Spin spinning={fetching}>
      <div>{!loggedIn && !fetching ? <GoogleSignInButton /> : null}</div>
    </Spin>
  )
}
