/* eslint-disable react-hooks/exhaustive-deps */
import { css } from '@emotion/react'
import { Button, message, Space } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { UsersTable } from '~/components/UsersTable'
import { getUsers, updateUsers } from '~/utils/location/api/endpoints'
import { User } from '~shared/api'
import { runAsync } from '~shared/utils'

function useIsMounted (): () => boolean {
  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return () => isMounted.current
}

export function Users (): JSX.Element {
  const isMounted = useIsMounted()
  const [users, setUsers] = useState<User[]>([])
  const [loading, setLoading] = useState(true)

  const [editing, setEditing] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [changes, setChanges] = useState<Record<string, Partial<User>>>({})

  useEffect(() => {
    runAsync(async () => {
      try {
        setLoading(true)
        const response = await getUsers({})
        if (!isMounted()) return
        setUsers(response)
        setLoading(false)
      } catch (e) {
        console.warn('[UsersPage]', e)
      }
    })
  }, [])

  return (
    <section>
      <Space
        css={css`
          margin-bottom: 1em;
        `}
      >
        {editing
          ? (
              [
                <Button
                  key='update'
                  type='primary'
                  loading={updating}
                  onClick={() => {
                    if (Object.keys(changes).length === 0) {
                      message
                        .error('No changes detected')
                        .then(() => {}, console.error)
                      return
                    }

                    runAsync(async () => {
                      try {
                        setUpdating(true)

                        const updates = users
                          .filter((user) => user.id in changes)
                          .map((u) => ({
                            id: u.id,
                            isVerified: changes[u.id].isVerified ?? u.isVerified,
                            isAdmin: changes[u.id].isAdmin ?? u.isAdmin
                          }))

                        const updateResponse = await updateUsers({
                          requestBody: updates
                        })

                        if (updateResponse.success) {
                          message
                            .success(updateResponse.message)
                            .then(() => {}, console.error)
                          if (isMounted()) {
                            setUpdating(false)
                            setEditing(false)
                            setUsers(updateResponse.users)
                          }
                        } else {
                          message
                            .error(updateResponse.message)
                            .then(() => {}, console.error)
                        }
                      } catch (e) {
                        message
                          .error(
                            'An error occurred when updating the user permissions.'
                          )
                          .then(() => {}, console.error)
                      } finally {
                        if (isMounted()) {
                          setUpdating(false)
                        }
                      }
                    })
                  }}
                >
                  Update
                </Button>,
                <Button
                  disabled={updating}
                  key='cancel'
                  onClick={() => {
                    setEditing(false)
                  }}
                >
                  Cancel
                </Button>
              ]
            )
          : (
            <Button
              type='primary'
              onClick={() => {
                setChanges({})
                setEditing(true)
              }}
            >
              Update Permissions
            </Button>
            )}
      </Space>

      <UsersTable
        users={users}
        loading={loading}
        editing={editing}
        onChange={(id, updatedUser) => {
          setChanges((oldChanges) => ({
            ...oldChanges,
            [id]: {
              ...oldChanges[id],
              ...updatedUser
            }
          }))
        }}
      />
    </section>
  )
}
