import { css } from '@emotion/css'
import { Modal } from 'antd'
import { MutableRefObject, useRef, useState } from 'react'
import { HexColorInput, HexColorPicker } from 'react-colorful'
import { Transforms } from 'slate'
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react'
import { Button, Icon, Symbol } from '../components/slate.example.components'
import { SectionElement } from '../types/slate.custom.types'
const { confirm } = Modal
export const SectionColorConfirmContent = ({
  colors
}: {
  colors: MutableRefObject<{
    background: string
    color: string
  }>
}): JSX.Element => {
  const [backgroundColor, setBackgroundColor] = useState(
    colors.current.background
  )
  const [textColor, setTextColor] = useState(colors.current.color)

  return (
    <>
      <div>
        <div>
          {/* <div
              className='color-value'
              style={{ borderRightColor: colors.current.background }}
            >
              Current background color is: {element.background}
            </div> */}
          <div>
            Pick background color:
            <HexColorInput
              size={6}
              color={backgroundColor}
              onChange={(v) => {
                setBackgroundColor(v)
                colors.current.background = v
              }}
            />
          </div>
          <div className='color-picker-div'>
            <HexColorPicker
              color={backgroundColor}
              onChange={(v) => {
                setBackgroundColor(v)
                colors.current.background = v
              }}
            />
          </div>
        </div>
        <div>
          {/* <div
              className='color-value'
              style={{ borderRightColor: element.color }}
            >
              Current text color is: {element.color}
            </div> */}
          <div>
            Pick text color:
            <HexColorInput
              size={6}
              color={textColor}
              onChange={(v) => {
                setTextColor(v)
                colors.current.color = v
              }}
            />
          </div>
          <div className='color-picker-div'>
            <HexColorPicker
              color={textColor}
              onChange={(v) => {
                setTextColor(v)
                colors.current.color = v
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export const Section = ({
  attributes,
  children,
  element,
  outline
}: Omit<RenderElementProps, 'element'> & {
  element: SectionElement
  outline: boolean
}): JSX.Element => {
  const editor = useSlateStatic()

  const path = ReactEditor.findPath(editor, element)

  const colors = useRef({
    background: element.background,
    color: element.color
  })
  console.log(path)
  console.log(colors.current)
  return (
    <div {...attributes}>
      <div
        style={{
          backgroundColor: `${colors.current.background}`,
          color: `${colors.current.color}`,
          // outlineStyle: outline ? 'dashed' : 'none',
          border: outline ? '2px dashed red' : 'none'
        }}
      >
        {children}
      </div>
      <div contentEditable={false}>
        <Button
          active
          onClick={() => {
            confirm({
              icon: <></>,
              content: <SectionColorConfirmContent colors={colors} />,
              onOk () {
                Transforms.setNodes(
                  editor,
                  {
                    background: colors.current.background,
                    color: colors.current.color
                  },
                  { at: path }
                )
              },
              onCancel () {
                console.log('Cancel')
              }
            })
          }}
          css={css`
            background-color: white;
            cursor: pointer;
          `}
        >
          <Icon>edit</Icon>
        </Button>
        <Button
          active
          onClick={() => {
            console.log(element)
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            navigator.clipboard.writeText(
              'slate-section94984301' + JSON.stringify(element)
            )
            // Transforms.removeNodes(editor, { at: path })
          }}
          css={css`
            background-color: white;
            cursor: pointer;
          `}
        >
          <Symbol>content_copy</Symbol>
        </Button>
        <Button
          active
          onClick={() => {
            console.log(element)
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            navigator.clipboard.writeText(
              'slate-section94984301' + JSON.stringify(element)
            )
            Transforms.removeNodes(editor, { at: path })
          }}
          css={css`
            background-color: white;
            cursor: pointer;
          `}
        >
          <Symbol>cut</Symbol>
        </Button>
        <Button
          active
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          css={css`
            position: absolute;
            top: 0.5em;
            left: 0.5em;
            background-color: white;
            cursor: pointer;
          `}
        >
          <Icon>delete</Icon>
        </Button>
      </div>
    </div>
  )
}
