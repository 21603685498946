/* eslint-disable react/prop-types */
import moment from 'moment'
import { useLocation } from 'react-router'
import {
  SermonAddOrEditOne,
  SermonAddOrEditOneProps
} from '~/components/SermonAddOrEditOne'
import { useDynamicContent } from '~/context'

export function EditSermon (): JSX.Element {
  const location = useLocation()
  const props: SermonAddOrEditOneProps = location.state as SermonAddOrEditOneProps

  const { speakers, sermonTypes } = useDynamicContent()
  return (
    <section>
      <SermonAddOrEditOne
        {...{
          ...props,
          date_of_sermon: moment(props.date_of_sermon, 'YYYY-MM-DD'),
          speakers: speakers.map((s) => s.name),
          types_of_sermons: sermonTypes.map((s) => s.name)
        }}
      />
    </section>
  )
}
