import { Button, Form, Input, message, Select, Space } from 'antd'
import { useEffect, useState } from 'react'
import { LinkButton } from '~/components/LinkButton'
import { useLanguange } from '~/utils/lang'
import { PAGE_BASE } from '~/utils/location'
import { createCategory } from '~/utils/location/api/endpoints'
import { useIsMounted } from '~/utils/useIsMounted'
import { runAsync } from '~shared/utils'

export function CategoryCreationPage (): JSX.Element {
  const [form] = Form.useForm<{
    name: string
    url: string
    lang: 'en' | 'zh'
  }>()

  const isMounted = useIsMounted()

  useEffect(() => {
    form.validateFields().catch(console.error)
  }, [form])
  const currentLanguage = useLanguange()
  const [lang, setLang] = useState(currentLanguage.currentLanguage)
  const [submitting, setSubmitting] = useState(false)

  return (
    <section>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout='horizontal'
        onValuesChange={(value) => {
          if ('lang' in value) {
            setLang(value.lang)
          }
        }}
        initialValues={{
          lang
        }}
      >
        <Form.Item
          label='Language'
          name='lang'
          required
          tooltip='The language of the page.'
        >
          <Select
            options={[
              { label: '中文', value: 'zh' },
              { label: 'English', value: 'en' }
            ]}
          />
        </Form.Item>
        <Form.Item
          label='Name'
          name='name'
          rules={[
            {
              required: true,
              message: 'Please enter a name!'
            }
          ]}
          tooltip='The name that will be shown in menus.'
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Url Prefix'
          name='url'
          rules={[
            {
              required: true,
              message: 'Please enter a url!'
            },
            {
              validator: async (_, value): Promise<void> => {
                if (!/^[0-9a-z-]*$/.test(value)) {
                  return await Promise.reject(
                    new Error(
                      'Only lowercase alphanumerical characters and dashes "-" are allowed.'
                    )
                  )
                }
              }
            }
          ]}
          tooltip='The url prefix for pages under this category.'
        >
          <Input
            addonBefore={
              window.location.origin +
              `/${PAGE_BASE}/${lang}/`.replace(/\/+/g, '/')
            }
          />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 14, offset: 4 }}>
          <Space>
            <Button
              loading={submitting}
              htmlType='submit'
              type='primary'
              onClick={() => {
                runAsync(async () => {
                  try {
                    setSubmitting(true)
                    const values = await form.validateFields()

                    const response = await createCategory({
                      requestBody: {
                        lang: values.lang,
                        url: values.url,
                        name: values.name
                      }
                    })

                    if (response.success) {
                      message
                        .success(response.message)
                        .then(() => {}, console.error)
                    } else {
                      message
                        .error(response.message)
                        .then(() => {}, console.error)
                    }
                  } catch (errorInfo) {
                    console.log('[CategoryCreation]', errorInfo)
                    message
                      .error(
                        "An error occurred. Please check that you aren't creating a category that already exists."
                      )
                      .then(() => {}, console.error)
                  } finally {
                    if (isMounted()) {
                      setSubmitting(false)
                    }
                  }
                })
              }}
            >
              Create Category
            </Button>
            <LinkButton to='../'>Cancel</LinkButton>
          </Space>
        </Form.Item>
      </Form>
    </section>
  )
}
