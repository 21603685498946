import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useLanguange } from '~/utils/lang'
import {
  getSermonSpeakers,
  getSermonTypes,
  getTopMenu
} from '~/utils/location/api/endpoints'
import { Menus, SermonTypes, Speakers } from '~shared/api'
import { runAsync } from '~shared/utils'
import { useIsMounted } from '../utils/useIsMounted'

interface DynamicContentState {
  menus: Menus
  speakers: Speakers
  sermonTypes: SermonTypes
}

const DynamicContent = createContext<null | DynamicContentState>(null)
const DynamicContentRefresh = createContext<null | (() => void)>(null)

const initialDynamicContentState: DynamicContentState = {
  menus: [],
  speakers: [],
  sermonTypes: []
}

export const DynamicContentProvider = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {
  const [dynamicContent, setDynamicContent] = useState<DynamicContentState>(
    initialDynamicContentState
  )

  const currentLanguage = useLanguange()
  const isMounted = useIsMounted()

  const fetchDynamicContent = useCallback(
    (toFetch?: string) => {
      async function refreshMenu (): Promise<void> {
        const response = await getTopMenu({
          requestBody: { lang: currentLanguage.currentLanguage }
        })

        if (!isMounted()) return
        if (response.status === 'success') {
          setDynamicContent((oldDynamicContent) => ({
            ...oldDynamicContent,
            menus: response.menus
          }))
        }
      }
      async function refreshSpeakers (): Promise<void> {
        const response = await getSermonSpeakers({
          requestBody: {}
        })

        if (!isMounted()) return

        if (response.status === 'success') {
          setDynamicContent((oldDynamicContent) => ({
            ...oldDynamicContent,
            speakers: response.speakers
          }))
        }
      }
      async function refreshSermonTypes (): Promise<void> {
        const response = await getSermonTypes({
          requestBody: {}
        })

        if (!isMounted()) return
        if (response.status === 'success') {
          setDynamicContent((oldDynamicContent) => ({
            ...oldDynamicContent,
            sermonTypes: response.types
          }))
        }
      }
      async function refreshAll (): Promise<void> {
        const [response1, response2, response3] = await Promise.all([
          getTopMenu({
            requestBody: { lang: currentLanguage.currentLanguage }
          }),
          getSermonSpeakers({
            requestBody: {}
          }),
          getSermonTypes({
            requestBody: {}
          })
        ])
        if (!isMounted()) return
        if (
          response1.status === 'success' &&
          response2.status === 'success' &&
          response3.status === 'success'
        ) {
          setDynamicContent((oldDynamicContent) => ({
            ...oldDynamicContent,
            menus: response1.menus,
            speakers: response2.speakers,
            sermonTypes: response3.types
          }))
        }
      }

      runAsync(async () => {
        try {
          switch (toFetch) {
            case 'menus':
              await refreshMenu()
              break
            case 'speakers':
              await refreshSpeakers()
              break
            case 'sermonTypes':
              await refreshSermonTypes()
              break
            default:
              await refreshAll()
          }
        } catch (e) {
          console.warn(
            '[TopMenu]',
            currentLanguage.currentLanguage,
            e,
            e?.response
          )
        }
      })
    },
    [currentLanguage.currentLanguage, isMounted]
  )

  useEffect(() => {
    fetchDynamicContent()
  }, [fetchDynamicContent])

  return (
    <DynamicContent.Provider value={dynamicContent}>
      <DynamicContentRefresh.Provider value={fetchDynamicContent}>
        {children}
      </DynamicContentRefresh.Provider>
    </DynamicContent.Provider>
  )
}

export const useDynamicContent = (): DynamicContentState => {
  const state = useContext(DynamicContent)
  if (state !== null) return state

  console.warn(
    '[useDynamicContent]',
    'WARNING: useDynamicContent is not being used with DynamicContentProvider'
  )
  return initialDynamicContentState
}

export const useDynamicContentRefresh = (): (() => void) => {
  const refresh = useContext(DynamicContentRefresh)
  if (refresh !== null) return refresh

  console.warn(
    '[useDynamicContentRefresh]',
    'WARNING: useDynamicContentRefresh is not being used with DynamicContentProvider'
  )
  return () => {}
}
