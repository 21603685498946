import { Button, Form, Input, message, Space } from 'antd'
import { useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router'
import { LinkButton } from '~/components/LinkButton'
import { useDynamicContentRefresh } from '~/context'
import { useLanguange } from '~/utils/lang'
import { PAGE_BASE } from '~/utils/location'
import { editCategory } from '~/utils/location/api/endpoints'
import { useIsMounted } from '~/utils/useIsMounted'
import { MenuItem } from '~shared/api'
import { isFalsy, runAsync } from '~shared/utils'

export function CategoryEditPage (): JSX.Element {
  const refresh = useDynamicContentRefresh()
  const { state } = useLocation<MenuItem | undefined>()

  const refreshDynamicContent = useDynamicContentRefresh()

  const [form] = Form.useForm<{
    name: string
    url: string
  }>()

  const isMounted = useIsMounted()

  useEffect(() => {
    form.validateFields().catch(console.error)
  }, [form])
  const currentLanguage = useLanguange()
  const lang = currentLanguage.currentLanguage
  const [submitting, setSubmitting] = useState(false)

  if (isFalsy(state)) {
    return <Redirect to={`/${lang}/admin/`} />
  }

  return (
    <section>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout='horizontal'
        initialValues={{
          name: state.text,
          url: state.url
        }}
      >
        <Form.Item
          label='Name'
          name='name'
          rules={[
            {
              required: true,
              message: 'Please enter a name!'
            }
          ]}
          tooltip='The name that will be shown in menus.'
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Url Prefix'
          name='url'
          rules={[
            {
              required: true,
              message: 'Please enter a url!'
            },
            {
              validator: async (_, value): Promise<void> => {
                if (!/^[0-9a-z-]*$/.test(value)) {
                  return await Promise.reject(
                    new Error(
                      'Only lowercase alphanumerical characters and dashes "-" are allowed.'
                    )
                  )
                }
              }
            }
          ]}
          tooltip='The url prefix for pages under this category.'
        >
          <Input
            addonBefore={
              window.location.origin +
              `/${PAGE_BASE}/${lang}/`.replace(/\/+/g, '/')
            }
          />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 14, offset: 4 }}>
          <Space>
            <Button
              loading={submitting}
              htmlType='submit'
              type='primary'
              onClick={() => {
                runAsync(async () => {
                  try {
                    setSubmitting(true)
                    const values = await form.validateFields()

                    console.log(values)

                    const response = await editCategory({
                      requestBody: {
                        id: state.id,
                        url: values.url,
                        name: values.name
                      }
                    })

                    if (response.success) {
                      refreshDynamicContent()
                      message
                        .success(response.message)
                        .then(() => {}, console.error)
                    } else {
                      message
                        .error(response.message)
                        .then(() => {}, console.error)
                    }
                  } catch (errorInfo) {
                    console.log('[CategoryEditPage]', errorInfo)
                    message
                      .error('An error occurred.')
                      .then(() => {}, console.error)
                  } finally {
                    if (isMounted()) {
                      setSubmitting(false)
                      refresh()
                    }
                  }
                })
              }}
            >
              Update Category
            </Button>
            <LinkButton to='../'>Cancel</LinkButton>
          </Space>
        </Form.Item>
      </Form>
    </section>
  )
}
