import axios from 'axios'
import { compile } from 'path-to-regexp'
import {
  DownloadEndpoint,
  Endpoint,
  HttpMethods,
  isTruthy,
  OptionalStringRecord,
  SingleFileFormEndpoint,
  StandardEndpoint
} from '~shared/utils'
import { API_BASE } from '../index'

type OptionalIfUndefined<K extends string, T> = T extends undefined
  ? { [x in K]?: T }
  : { [x in K]: T }

type ResolveEndpointUrlArgs<
  ReqParam,
  ReqQuery extends OptionalStringRecord
> = OptionalIfUndefined<'requestParams', ReqParam> &
OptionalIfUndefined<'requestQuery', ReqQuery>

export function resolveEndpointUrl<
  ReqParam = undefined,
  ReqQuery extends OptionalStringRecord = undefined
> (
  endpoint: Endpoint<ReqParam, ReqQuery, HttpMethods>,
  { requestParams, requestQuery }: ResolveEndpointUrlArgs<ReqParam, ReqQuery>
): string {
  return `${compile(`${API_BASE}${endpoint.apiPath}`, {
    encode: encodeURIComponent
  })(requestParams ?? {})}?${new URLSearchParams(requestQuery).toString()}`
}

type StandardApiCallArgs<
  ReqBody,
  ReqParam,
  ReqQuery extends OptionalStringRecord
> = OptionalIfUndefined<'requestBody', ReqBody> &
OptionalIfUndefined<'requestParams', ReqParam> &
OptionalIfUndefined<'requestQuery', ReqQuery>

export function createStandardApiCall<
  ResBody = undefined,
  ReqBody = undefined,
  ReqParam = undefined,
  ReqQuery extends OptionalStringRecord = undefined
> (
  endpoint: StandardEndpoint<ResBody, ReqBody, ReqParam, ReqQuery>
): (
    request: StandardApiCallArgs<ReqBody, ReqParam, ReqQuery>
  ) => Promise<ResBody> {
  return async ({ requestBody, requestParams, requestQuery }) => {
    const response = await axios.request<ResBody>({
      method: endpoint.method,
      url: compile(`${API_BASE}${endpoint.apiPath}`, {
        encode: encodeURIComponent
      })(requestParams ?? {}),
      params: requestQuery,
      data: requestBody
    })

    return response.data
  }
}

export function createDownloadApiCall<
  ReqBody = undefined,
  ReqParam = undefined,
  ReqQuery extends OptionalStringRecord = undefined
> (
  endpoint: DownloadEndpoint<ReqBody, ReqParam, ReqQuery>
): (
    request: StandardApiCallArgs<ReqBody, ReqParam, ReqQuery>
  ) => Promise<File> {
  return async ({ requestBody, requestParams, requestQuery }) => {
    const response = await axios.request<ArrayBuffer>({
      responseType: 'arraybuffer',
      method: endpoint.method,
      url: compile(`${API_BASE}${endpoint.apiPath}`, {
        encode: encodeURIComponent
      })(requestParams ?? {}),
      params: requestQuery,
      data: requestBody
    })

    const fileName = /filename="(.*)"$/.exec(
      response.headers?.['content-disposition'] ?? ''
    )?.[1]

    const file = new File([response.data], fileName ?? 'UNKNOWN')

    return file
  }
}

type SingleFileFormArgs<
  ReqForm extends OptionalStringRecord,
  ReqParam,
  ReqQuery extends OptionalStringRecord
> = OptionalIfUndefined<'requestForm', ReqForm> &
OptionalIfUndefined<'requestParams', ReqParam> &
OptionalIfUndefined<'requestQuery', ReqQuery> & { file: File }

export function createSingleFileFormApiCall<
  ResBody = undefined,
  FormBody extends OptionalStringRecord = undefined,
  ReqParam = undefined,
  ReqQuery extends OptionalStringRecord = undefined
> (
  endpoint: SingleFileFormEndpoint<ResBody, FormBody, ReqParam, ReqQuery>
): (
    request: SingleFileFormArgs<FormBody, ReqParam, ReqQuery>
  ) => Promise<ResBody> {
  return async ({ requestForm, requestParams, requestQuery, file }) => {
    const formData = new FormData()
    if (isTruthy(requestForm)) {
      for (const [key, value] of Object.entries(requestForm)) {
        formData.set(key, value)
      }
    }

    formData.set(endpoint.fileFormName, file)

    const response = await axios.request<ResBody>({
      method: endpoint.method,
      url: compile(`${API_BASE}${endpoint.apiPath}`, {
        encode: encodeURIComponent
      })(requestParams ?? {}),
      params: requestQuery,
      data: formData
    })

    return response.data
  }
}
