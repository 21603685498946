/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { DownOutlined } from '@ant-design/icons'
import { css } from '@emotion/css'
import { Dropdown, Menu, Space } from 'antd'
import imageExtensions from 'image-extensions'
import isUrl from 'is-url'

import { useEffect, useMemo, useState } from 'react'
import {
  BaseEditor,
  createEditor,
  Descendant,
  Editor,
  Element as SlateElement,
  Transforms
} from 'slate'
import { HistoryEditor, withHistory } from 'slate-history'
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  useSlate,
  withReact
} from 'slate-react'
import { isTruthy } from '~shared/utils'
import { Button, Icon, Toolbar } from '../components/slate.example.components'
import {
  CustomEditor,
  CustomElement,
  CustomElementTypes,
  CustomText,
  SermonTableElement
} from '../types/slate.custom.types'
import { Image } from './Image'
import { insertImage, InsertImageModal } from './InsertImageModal'
import { InsertLinkModal } from './InsertLinkModal'
import { InsertSectionModal } from './InsertSectionModal'
import { LinkComponent } from './Link'
import { Section } from './Section'
import { SermonTablePreview } from './SermonTablePreview'
import { getLink, isLinkActive, withInlines } from './withInlines'

const LIST_TYPES = ['numbered-list', 'bulleted-list']

export interface SlateEditorProps {
  initialValue?: Descendant[]
  onChange?: (newValue: Descendant[]) => void
}

export function SlateEditor ({
  initialValue,
  onChange
}: SlateEditorProps): JSX.Element {
  const [value, setValue] = useState<Descendant[]>(
    initialValue ?? [
      {
        type: 'paragraph',
        children: [
          {
            text: ''
          }
        ]
      }
    ]
  )

  useEffect(() => {
    setValue(initialValue ?? [{ text: '' }])
  }, [initialValue])
  const withPaste = (editor: CustomEditor): CustomEditor => {
    const { insertData } = editor
    editor.insertData = (data) => {
      const text = data.getData('text/plain')

      if (text.indexOf('slate-section94984301') === 0) {
        const sectionstr = text.replace('slate-section94984301', '')
        const element = JSON.parse(sectionstr)
        Transforms.insertNodes(editor, element)
        return
      }

      insertData(data)
    }

    return editor
  }
  const editor = useMemo(
    () =>
      withPaste(
        withInlines(withImages(withHistory(withReact(createEditor()))))
      ),
    []
  )

  const [httpLink, setHttpLink] = useState<string>('')
  const [bgColor, setBgColor] = useState('#FFFFFF')
  const [foreColor, setForeColor] = useState('#000000')
  const [isImageModalVisible, setIsImageModalVisible] = useState(false)
  const [isSectionModalVisible, setIsSectionModalVisible] = useState(false)
  const [isLinkModalVisible, setIsLinkModalVisible] = useState(false)

  const showImageModal = (): void => {
    setIsImageModalVisible(true)
  }
  const showSectionModal = (): void => {
    setIsSectionModalVisible(true)
  }
  const showLinkModal = (): void => {
    setIsLinkModalVisible(true)
  }

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={(value) => {
        const isAstChange = editor.operations.some(
          (op) => op.type !== 'set_selection'
        )
        if (isAstChange) {
          console.log(value)
        }
        setValue(value)
        onChange?.(value)
      }}
    >
      <Toolbar>
        <Button
          title='Insert Section'
          onMouseDown={() => {
            showSectionModal()
          }}
        >
          <Icon>crop_square</Icon>
        </Button>
        <Button
          title='Insert Image'
          onMouseDown={() => {
            showImageModal()
          }}
        >
          <Icon>image</Icon>
        </Button>
        <Button
          title='Insert Link'
          active={isLinkActive(editor)}
          onMouseDown={() => {
            // const { selection } = editor
            if (isLinkActive(editor)) {
              setHttpLink(getLink(editor))
              showLinkModal()
            } else {
              showLinkModal()
            }
          }}
        >
          <Icon>link</Icon>
        </Button>
        <MarkButton format='bold' icon='format_bold' />
        <MarkButton format='italic' icon='format_italic' />
        <BlockButton format='heading-three' icon='title' />
        <BlockButton format='heading-four' icon='text_fields' />
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => {
                switch (e.key) {
                  case 'sermon-table': {
                    const section: SermonTableElement = {
                      type: e.key,
                      children: [{ text: '' }]
                    }
                    Transforms.insertNodes(editor, section)
                  }
                }
              }}
            >
              <Menu.Item key='sermon-table'>Sermon Table</Menu.Item>
            </Menu>
          }
        >
          <Button>
            <Space>
              Add Special Elements
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Toolbar>
      <Editable
        renderElement={Element}
        renderLeaf={Leaf}
        autoFocus
        placeholder='Enter some text...'
      />
      <InsertImageModal
        isModalVisible={isImageModalVisible}
        setIsModalVisible={setIsImageModalVisible}
      />
      <InsertSectionModal
        isModalVisible={isSectionModalVisible}
        setIsModalVisible={setIsSectionModalVisible}
        bgColor={bgColor}
        foreColor={foreColor}
        setBgColor={setBgColor}
        setForeColor={setForeColor}
      />
      <InsertLinkModal
        isModalVisible={isLinkModalVisible}
        setIsModalVisible={setIsLinkModalVisible}
        httpLink={httpLink}
        setHttpLink={setHttpLink}
      />
    </Slate>
  )
}

const MarkButton = ({
  format,
  icon
}: {
  format: any
  icon: any
}): JSX.Element => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onClick={() => {
        toggleMark(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}
const isMarkActive = (
  editor: CustomEditor,
  format: keyof Omit<CustomText, 'text'>
): boolean => {
  const marks = Editor.marks(editor)
  return marks != null ? marks[format] === true : false
}
const toggleMark = (
  editor: CustomEditor,
  format: 'bold' | 'italic' | 'code'
): void => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true) // this just set the leaf.format=true
  }
}
const toggleBlock = (
  editor: CustomEditor,
  format: CustomElementTypes
): void => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)
  console.log('format', format)
  console.log('isActive', isActive)
  console.log('isList', isList)

  Transforms.unwrapNodes(editor, {
    match: (n) => {
      return (
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type)
      )
    },

    split: true
  })
  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = {
      type: format as 'bulleted-list' | 'numbered-list',
      children: []
    }
    Transforms.wrapNodes(
      editor,
      block as Extract<
      CustomElement,
      { type: 'bulleted-list' | 'numbered-list' }
      >
    )
  }
}

const Leaf = ({ attributes, children, leaf }: RenderLeafProps): JSX.Element => {
  if (isTruthy(leaf.bold)) {
    children = <strong>{children}</strong>
  }

  if (isTruthy(leaf.italic)) {
    children = <em>{children}</em>
  }

  // return <span {...attributes}>{children}</span>
  return (
    <span
      // The following is a workaround for a Chromium bug where,
      // if you have an inline at the end of a block,
      // clicking the end of a block puts the cursor inside the inline
      // instead of inside the final {text: ''} node
      // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
      className={
        leaf.text === ''
          ? css`
              padding-left: 0.1px;
            `
          : undefined
      }
      {...attributes}
      style={{
        fontWeight: leaf.bold ? 'bold' : 'normal',
        fontStyle: leaf.italic ? 'italic' : 'normal'
      }}
    >
      {children}
    </span>
  )
}

const BlockButton = ({
  format,
  icon
}: {
  format: CustomElementTypes
  icon: string
}): JSX.Element => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={() => {
        console.log(format)

        toggleBlock(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}
// let c: CustomElement
const isBlockActive = (
  editor: CustomEditor,
  format: CustomElementTypes
): boolean => {
  const { selection } = editor
  if (selection == null) return false

  // console.log('selection', selection)
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => {
        return (
          !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format
        )
      }
    })
  )

  return Boolean(match)
}

const withImages = (
  editor: BaseEditor & ReactEditor & HistoryEditor
): BaseEditor & ReactEditor & HistoryEditor => {
  const { insertData, isVoid } = editor

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element)
  }

  // data is of react DataTransfer type, DataTransfer is in MDN web api
  editor.insertData = (data) => {
    const text = data.getData('text/plain')
    const { files } = data

    if (isTruthy(files) && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader() // FileReader is MDN web api
        const [mime] = file.type.split('/')

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result
            insertImage(editor, url)
          })

          reader.readAsDataURL(file)
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const Element = ({
  attributes,
  children,
  element
}: RenderElementProps): JSX.Element => {
  switch (element.type) {
    case 'heading-three':
      return (
        <p {...attributes} style={{ fontSize: '2em' }}>
          {children}
        </p>
      )
    case 'heading-four':
      return (
        <p {...attributes} style={{ fontSize: '1.5em' }}>
          {children}
        </p>
      )
    case 'section':
      return <Section outline {...{ attributes, children, element }} />
    case 'image':
      return <Image {...{ attributes, children, element }} />
    case 'sermon-table':
      return <SermonTablePreview {...{ attributes, children, element }} />
    case 'link':
      return <LinkComponent {...{ attributes, children, element }} />
    default:
      return <p {...attributes}>{children}</p>
  }
}

const isImageUrl = (url: string): boolean => {
  if (url === '') return false
  if (!isUrl(url)) return false
  const ext = new URL(url).pathname.split('.').pop()
  return imageExtensions.includes(ext as string)
}
