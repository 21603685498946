import { Button, Form, Input, message, Skeleton, Space } from 'antd'
import { useEffect, useState } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router'
import { LinkButton } from '~/components/LinkButton'
import { SlateEditor } from '~/components/SlateEditor'
import { useDynamicContentRefresh } from '~/context'
import { useLanguange } from '~/utils/lang'
import { PAGE_BASE } from '~/utils/location'
import { editPage, getPageContent } from '~/utils/location/api/endpoints'
import { useIsMounted } from '~/utils/useIsMounted'
import { LinkItem } from '~shared/api'
import { isFalsy, runAsync } from '~shared/utils'
import { isJson } from './Content'

export function PageEditPage (): JSX.Element {
  const { state } = useLocation<
  (LinkItem & { category: string }) | undefined
  >()

  const [form] = Form.useForm<{
    name: string
    content: string
    url: string
  }>()

  const isMounted = useIsMounted()

  const refreshDynamicContent = useDynamicContentRefresh()

  useEffect(() => {
    form.validateFields().catch(console.error)
  }, [form])
  const currentLanguage = useLanguange()
  const lang = currentLanguage.currentLanguage
  const [submitting, setSubmitting] = useState(false)
  const [markdown, setMarkdown] = useState('')
  const [contentLoading, setContentLoading] = useState(true)
  const history = useHistory()
  const categoryLink = state?.category ?? ''

  const id = state?.id

  useEffect(() => {
    let mounted = true

    if (id !== undefined) {
      runAsync(async () => {
        try {
          setContentLoading(true)
          const response = await getPageContent({
            requestBody: {
              id: id
            }
          })
          if (!mounted) return
          if (response.status === 'success') {
            setMarkdown(response.content)
            setContentLoading(false)
          } else {
            history.replace(`/${lang}/admin/`)
          }
        } catch (e) {
          console.warn('[PageEditPage]', e)
          if (e?.response.status === 404) {
            history.replace(`/${lang}/admin/`)
          }
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [history, lang, id])

  if (isFalsy(state)) {
    return <Redirect to={`/${lang}/admin/`} />
  }

  return (
    <section>
      {!contentLoading
        ? (
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout='horizontal'
            initialValues={{
              name: state.text,
              url: state.url,
              content: markdown
            }}
          >
            <Form.Item
              label='Name'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Please enter a name!'
                }
              ]}
              tooltip='The name that will be shown in menus.'
            >
              <Input />
            </Form.Item>
            <Form.Item
              label='Url'
              name='url'
              rules={[
                {
                  validator: async (_, value): Promise<void> => {
                    if (!/^[0-9a-z-]*$/.test(value)) {
                      return await Promise.reject(
                        new Error(
                          'Only lowercase alphanumerical characters and dashes "-" are allowed.'
                        )
                      )
                    }
                  }
                }
              ]}
              tooltip='The url of the page.'
            >
              <Input
                addonBefore={
                window.location.origin +
                `/${PAGE_BASE}/${lang}/${categoryLink}/`.replace(/\/+/g, '/')
              }
              />
            </Form.Item>
            <Form.Item label='Page Content' name='content' required>
              {isJson(markdown)
                ? (
                  <SlateEditor initialValue={JSON.parse(markdown)} />
                  )
                : (
                  <Input.TextArea />
                  )}
            </Form.Item>
            <Form.Item wrapperCol={{ span: 14, offset: 4 }}>
              <Space>
                <Button
                  loading={submitting}
                  htmlType='submit'
                  type='primary'
                  onClick={() => {
                    runAsync(async () => {
                      try {
                        setSubmitting(true)
                        const values = await form.validateFields()

                        const response = await editPage({
                          requestBody: {
                            id: state.id,
                            page:
                            typeof values.content !== 'string'
                              ? JSON.stringify(values.content)
                              : values.content,
                            url: values.url,
                            name: values.name
                          }
                        })

                        if (response.success) {
                          refreshDynamicContent()
                          message
                            .success(response.message)
                            .then(() => {}, console.error)
                          history.push('../')
                        } else {
                          message
                            .error(response.message)
                            .then(() => {}, console.error)
                        }
                      } catch (errorInfo) {
                        console.log('[PageCreation]', errorInfo)
                        message
                          .error('An error occurred.')
                          .then(() => {}, console.error)
                      } finally {
                        if (isMounted()) {
                          setSubmitting(false)
                        }
                      }
                    })
                  }}
                >
                  Update Page
                </Button>
                <LinkButton to='../'>Cancel</LinkButton>
              </Space>
            </Form.Item>
          </Form>
          )
        : (
          <Skeleton />
          )}
    </section>
  )
}
