import { useEffect, useRef } from 'react'
import { useGoogleApiStatus } from '~/context'

export function GoogleSignInButton (): JSX.Element {
  const divRef = useRef<HTMLDivElement>(null)
  const loaded = useGoogleApiStatus()

  useEffect(() => {
    const div = divRef.current
    if (loaded && div !== null) {
      window.google.accounts.id.renderButton(div, {})
    }
  }, [loaded])

  return <div ref={divRef} />
}
