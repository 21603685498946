import { Button, ButtonProps } from 'antd'
import { Link, LinkProps, useHistory } from 'react-router-dom'
import { isTruthy } from '~shared/utils'

function isModifiedEvent (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
): boolean {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

export function LinkButton ({
  onClick,
  to,
  replace,
  ...props
}: LinkProps & { to: string } & ButtonProps): JSX.Element {
  const history = useHistory()

  return (
    <Link
      to={to}
      replace={replace}
      component={Button}
      {...props}
      onClick={(event) => {
        try {
          onClick?.(event)
        } catch (ex) {
          event.preventDefault()
          throw ex
        }

        if (
          !event.defaultPrevented && // onClick prevented default
          event.button === 0 && // ignore everything but left clicks
          !isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
          event.preventDefault()
          history[isTruthy(replace) ? 'replace' : 'push']({ pathname: to })
        }
      }}
    />
  )
}
