import { css } from '@emotion/css'
import { useEffect, useState } from 'react'
import { Transforms } from 'slate'
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react'
import { getSermons } from '~/utils/location/api/endpoints'
import { useIsMounted } from '~/utils/useIsMounted'
import { Sermon } from '~shared/api'
import { runAsync } from '~shared/utils'
import { Button, Icon } from '../components/slate.example.components'
import { SermonTableElement } from '../types/slate.custom.types'
import { SermonsTable } from './SermonsTable'
export const SermonTablePreview = ({
  attributes,
  children,
  element
}: Omit<RenderElementProps, 'element'> & {
  element: SermonTableElement
}): JSX.Element => {
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor, element)
  const isMounted = useIsMounted()
  const [sermons, setSermons] = useState<Sermon[]>([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    runAsync(async () => {
      try {
        setLoading(true)
        const response = await getSermons({ requestBody: {} })
        if (!isMounted()) return
        if (response.status === 'success') {
          setSermons(response.sermons)
          setLoading(false)
        }
      } catch (e) {
        console.warn('[SermonsPage]', e)
      }
    })
  }, [isMounted])

  return (
    <div>
      <div
        style={{
          userSelect: 'none',
          position: 'relative'
        }}
        contentEditable={false}
        {...attributes}
      >
        <div>
          <SermonsTable loading={loading} sermons={sermons} />
        </div>
        <Button
          title='Remove Table'
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          css={css`
            position: absolute;
            bottom: 0.5em;
            left: 0.5em;
            background-color: white;
            z-index: 100;
            cursor: pointer;
            color: black;
          `}
        >
          <Icon>delete</Icon>
        </Button>
        {children}
      </div>
    </div>
  )
}
