import { Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { MenuItem, Menus, SubMenu } from '~shared/api'
import { isNullLike } from '~shared/utils'
import EditableMenuItem from './EditableMenuItem'
import './TopMenuEditable.less'

interface MenuItemDropZoneProps {
  parentPosition: number
  position: number
  onPositionChange: (src: MenuPosition, dest: MenuPosition) => void
}

const MenuItemDropZone = ({
  parentPosition,
  position,
  onPositionChange
}: MenuItemDropZoneProps): JSX.Element => {
  // const refreshMenu = useDynamicContentRefresh()

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ['link'],
      drop: async (item: {
        menuPosition: number
        submenuPosition: number | null
      }) => {
        onPositionChange(item, {
          menuPosition: parentPosition,
          submenuPosition: position
        })
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver()
      })
    }),
    [onPositionChange, parentPosition, position]
  )
  return (
    <div className='editable-menu-item-dropzone' ref={drop}>
      <div
        className='editable-menu-item-dropzone-overlay'
        style={{ display: isOver ? 'block' : 'none' }}
        ref={drop}
      />
    </div>
  )
}
interface MenuColumnProps {
  submenu: SubMenu
  position: number
  onPositionChange: (src: MenuPosition, dest: MenuPosition) => void
  getMenuItemActions?: (data: {
    id: number
    type: 'link' | 'submenu'
    topMenuPosition: number
    itemPosition: number | null
    onChange: (newMenus: Menus | ((oldMenus: Menus) => Menus)) => void
  }) => React.ReactNode
  onChange: (newMenus: Menus | ((oldMenus: Menus) => Menus)) => void
}

const MenuColumn = ({
  submenu,
  position,
  onPositionChange,
  getMenuItemActions,
  onChange
}: MenuColumnProps): JSX.Element => {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'menu-column',
      item: {
        menuPosition: position,
        submenuPosition: null
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.75 : 1
      })
    }),
    [position]
  )

  return (
    <div
      ref={dragRef}
      className='top-menu-editable-submenu'
      style={{ opacity }}
    >
      <EditableMenuItem
        type={'links' in submenu ? 'submenu' : 'link'}
        id={submenu.id ?? -1}
        text={submenu.text}
        topMenuPosition={position}
        itemPosition={null}
        onChange={onChange}
        getActions={getMenuItemActions}
      />

      {'links' in submenu && (
        <>
          <Divider style={{ margin: '0.25em' }} />

          {submenu.links.map((li, idx) => (
            <React.Fragment key={li.id}>
              <MenuItemDropZone
                onPositionChange={onPositionChange}
                parentPosition={position}
                position={idx}
              />
              <EditableMenuItem
                id={li.id}
                type='link'
                text={li.text}
                topMenuPosition={position}
                itemPosition={idx}
                onChange={onChange}
                getActions={getMenuItemActions}
              />
            </React.Fragment>
          ))}

          <MenuItemDropZone
            onPositionChange={onPositionChange}
            parentPosition={position}
            position={submenu.links.length}
          />
        </>
      )}
    </div>
  )
}

interface MenuColumnDropZoneProps {
  position: number
  onPositionChange: (src: MenuPosition, dest: MenuPosition) => void
}

const MenuColumnDropZone = ({
  position,
  onPositionChange
}: MenuColumnDropZoneProps): JSX.Element => {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ['menu-column', 'link'],
      drop: async (item: {
        menuPosition: number
        submenuPosition?: number | null
      }) => {
        onPositionChange(item, {
          menuPosition: position
        })
      },

      collect: (monitor) => ({
        isOver: monitor.isOver()
      })
    }),
    [position, onPositionChange]
  )
  return (
    <div className='editable-submenu-item-dropzone' ref={drop}>
      <div
        className='editable-submenu-item-dropzone-overlay'
        style={{ display: isOver ? 'block' : 'none' }}
      />
    </div>
  )
}

interface MenuPosition {
  /** The top menu index of an item */
  menuPosition: number
  /** The top menu index of an item */
  submenuPosition?: number | null
}

export interface TopMenuEditableProps {
  menus: Menus
  onChange?: (newMenus: Menus) => void
  getMenuItemActions?: (data: {
    id: number
    type: 'link' | 'submenu'
    topMenuPosition: number
    itemPosition: number | null
    onChange: (newMenus: Menus | ((oldMenus: Menus) => Menus)) => void
  }) => React.ReactNode
}

export const TopMenuEditable = ({
  menus: initialMenus,
  onChange,
  getMenuItemActions
}: TopMenuEditableProps): JSX.Element => {
  const [menus, setMenus] = useState(initialMenus)

  useEffect(() => {
    setMenus(initialMenus)
  }, [initialMenus])

  const handleMove = (src: MenuPosition, dest: MenuPosition): void => {
    // skip swapping if no swapping is needed
    if (
      (src.menuPosition === dest.menuPosition &&
        src.submenuPosition === dest.submenuPosition) ||
      (src.menuPosition + 1 === dest.menuPosition &&
        isNullLike(dest.submenuPosition) &&
        isNullLike(src.submenuPosition)) ||
      (src.menuPosition === dest.menuPosition &&
        !isNullLike(src.submenuPosition) &&
        src.submenuPosition + 1 === dest.submenuPosition)
    ) {
      return
    }

    const newMenus = menus.slice()

    // handle simple reordering of top menu items
    if (isNullLike(dest.submenuPosition) && isNullLike(src.submenuPosition)) {
      const movedItem = newMenus.splice(src.menuPosition, 1)
      const indexAdjustment = dest.menuPosition > src.menuPosition ? 1 : 0
      newMenus.splice(dest.menuPosition - indexAdjustment, 0, ...movedItem)
    } else if (isNullLike(src.submenuPosition)) {
      // move the dragged item to the dragged position
      // adjusting for index changes from removing the
      // movedItem from the array

      // make a copy of this object because its properties will be modified
      const destinationSubmenu = {
        ...(newMenus[dest.menuPosition] as MenuItem)
      }
      destinationSubmenu.links = destinationSubmenu.links.slice()
      newMenus[dest.menuPosition] = destinationSubmenu

      const movedItem = newMenus.splice(src.menuPosition, 1)
      destinationSubmenu.links.splice(
        dest.submenuPosition as number,
        0,
        ...movedItem
      )

      // move page link to the top
    } else {
      // make a copy of this object because its properties will be modified
      const srcSubmenu = { ...(newMenus[src.menuPosition] as MenuItem) }
      srcSubmenu.links = srcSubmenu.links.slice()
      newMenus[src.menuPosition] = srcSubmenu

      const movedItem = srcSubmenu.links.splice(src.submenuPosition, 1)

      if (isNullLike(dest.submenuPosition)) {
        newMenus.splice(dest.menuPosition, 0, ...movedItem)
      } else {
        // make a copy of this object because its properties will be modified
        const destinationSubmenu = {
          ...(newMenus[dest.menuPosition] as MenuItem)
        }
        destinationSubmenu.links = destinationSubmenu.links.slice()
        newMenus[dest.menuPosition] = destinationSubmenu

        const indexAdjustment =
          dest.menuPosition === src.menuPosition &&
          dest.submenuPosition > src.submenuPosition
            ? 1
            : 0
        destinationSubmenu.links.splice(
          dest.submenuPosition - indexAdjustment,
          0,
          ...movedItem
        )
      }
    }
    setMenus(newMenus)
    onChange?.(newMenus)
  }

  return (
    <div className='top-menu-editable-container'>
      {menus.map((menuColumn, idx) => {
        return (
          <React.Fragment
            key={`${'links' in menuColumn ? 'category' : 'toplink'}-${
              menuColumn.id
            }`}
          >
            <MenuColumnDropZone position={idx} onPositionChange={handleMove} />
            <MenuColumn
              submenu={menuColumn}
              position={idx}
              onPositionChange={handleMove}
              onChange={(newMenusOrUpdateFunction) => {
                const oldMenus = menus
                const newMenus =
                  typeof newMenusOrUpdateFunction === 'function'
                    ? newMenusOrUpdateFunction(oldMenus)
                    : newMenusOrUpdateFunction
                setMenus(newMenus)
                onChange?.(newMenus)
              }}
              getMenuItemActions={getMenuItemActions}
            />
          </React.Fragment>
        )
      })}
      <MenuColumnDropZone
        position={menus.length}
        onPositionChange={handleMove}
      />
    </div>
  )
}
