import { Button, Space } from 'antd'
import { useRouteMatch } from 'react-router'
import { LinkButton } from '~/components/LinkButton'
import { MenuEdit } from './MenuEdit'

export function AdminDashboard (): JSX.Element {
  const match = useRouteMatch()
  return (
    <section>
      <Space>
        <LinkButton
          component={Button}
          type='primary'
          to={`${match.url}/page/add`.replace(/\/+/g, '/')}
        >
          Create Page
        </LinkButton>
        <LinkButton
          component={Button}
          type='primary'
          to={`${match.url}/category/add`.replace(/\/+/g, '/')}
        >
          Create Category
        </LinkButton>
        <LinkButton
          component={Button}
          type='primary'
          to={`${match.url}/sermon/`.replace(/\/+/g, '/')}
        >
          Manage Sermons
        </LinkButton>
        <LinkButton
          component={Button}
          type='primary'
          to={`${match.url}/user/`.replace(/\/+/g, '/')}
        >
          Manage Users
        </LinkButton>
      </Space>
      <MenuEdit />
    </section>
  )
}
