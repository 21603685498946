/* eslint-disable react-hooks/exhaustive-deps */
import { PlusOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { message } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { LinkButton } from '~/components/LinkButton'
import { SermonsTable } from '~/components/SermonsTable'
import { deleteSermon, getSermons } from '~/utils/location/api/endpoints'
import { Sermon } from '~shared/api/content/sermons'
import { runAsync } from '~shared/utils'

function useIsMounted (): () => boolean {
  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return () => isMounted.current
}

export function Sermons (): JSX.Element {
  const isMounted = useIsMounted()
  const [sermons, setSermons] = useState<Sermon[]>([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    runAsync(async () => {
      try {
        setLoading(true)
        const response = await getSermons({ requestBody: {} })
        if (!isMounted()) return
        if (response.status === 'success') {
          setSermons(response.sermons)
          setLoading(false)
        }
      } catch (e) {
        console.warn('[SermonsPage]', e)
      }
    })
  }, [])

  function removeSermon (id: number): void {
    deleteSermon({ requestBody: { id } })
      .then(() => {
        setSermons(sermons.filter((s) => s.id !== id))
      })
      .catch(() => {
        message
          .error('An error occurred when trying the delete the sermon.')
          .then(() => {}, console.error)
      })
  }

  return (
    <section>
      <LinkButton
        to='add'
        type='primary'
        shape='round'
        size='middle'
        icon={<PlusOutlined style={{ verticalAlign: 'baseline' }} />}
        css={css`
          margin-bottom: 1em;
        `}
      >
        Add Sermon
      </LinkButton>
      <SermonsTable
        admin
        sermons={sermons}
        removeSermon={removeSermon}
        loading={loading}
      />
    </section>
  )
}
