import { Button, Result } from 'antd'
import { Link } from 'react-router-dom'

export function Page404 (): JSX.Element {
  return (
    <Result
      status='404'
      title='404'
      subTitle='Sorry, the page you visited does not exist.'
      extra={
        <Link component={Button} type='primary' to='/'>
          Back Home
        </Link>
      }
    />
  )
}
