import { useDrag } from 'react-dnd'
import { Menus } from '~shared/api'
import './EditableMenuItem.less'

interface EditableMenuItemProps {
  id: number
  type: 'link' | 'submenu'
  text: string
  topMenuPosition: number
  itemPosition: number | null
  getActions?: (data: {
    id: number
    type: 'link' | 'submenu'
    topMenuPosition: number
    itemPosition: number | null
    onChange: (newMenus: Menus | ((oldMenus: Menus) => Menus)) => void
  }) => React.ReactNode
  onChange: (newMenus: Menus | ((oldMenus: Menus) => Menus)) => void
}

const EditableMenuItem = ({
  id,
  type,
  text,
  topMenuPosition,
  itemPosition,
  getActions,
  onChange
}: EditableMenuItemProps): JSX.Element => {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'link',
      item: {
        menuPosition: topMenuPosition,
        submenuPosition: itemPosition
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.75 : 1
      })
    }),
    [topMenuPosition, itemPosition]
  )

  return (
    <div>
      <div
        id={`${type}-${id ?? -1}`}
        className='editable-menu-item-container'
        ref={type === 'link' ? dragRef : null}
        style={{ opacity }}
      >
        <div className='editable-menu-item-text-row'>
          <span> {text} </span>
          <span style={{ paddingLeft: '1em' }}>
            {getActions?.({
              id,
              itemPosition,
              onChange,
              topMenuPosition,
              type
            })}
          </span>
        </div>
      </div>
    </div>
  )
}

export default EditableMenuItem
