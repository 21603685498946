import { Skeleton } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { MarkdownRenderer } from '~/components/MarkdownRenderer'
import { SlateRendererRaw } from '~/components/SlateRenderRaw'
import { useLanguange } from '~/utils/lang'
import { getPageContent } from '~/utils/location/api/endpoints'
import { runAsync } from '~shared/utils'
import { Page404 } from './404'

export function isJson (text: string): boolean {
  try {
    JSON.parse(text)
    return true
  } catch {
    return false
  }
}

export function ContentPage (): JSX.Element {
  const [markdown, setMarkdown] = useState<string | null>(null)
  const [is404, setIs404] = useState(false)

  const history = useHistory()
  const pageData = useLanguange()

  useEffect(() => {
    let mounted = true

    runAsync(async () => {
      try {
        setMarkdown(null)
        setIs404(false)
        const response = await getPageContent({
          requestBody: { page: pageData.path, lang: pageData.currentLanguage }
        })
        if (!mounted) return
        if (response.status === 'success') {
          setMarkdown(response.content)
        } else {
          setIs404(true)
        }
      } catch (e) {
        console.warn(
          '[ContentPage]',
          pageData.currentLanguage,
          pageData.path,
          e
        )
        if (e?.response.status === 404) {
          setIs404(true)
        }
      }
    })

    return () => {
      mounted = false
    }
  }, [history, pageData.currentLanguage, pageData.path])

  if (is404) {
    return <Page404 />
  }

  if (markdown === null) {
    return <Skeleton active />
  }

  if (isJson(markdown)) {
    return <SlateRendererRaw value={JSON.parse(markdown)} />
  }

  return <MarkdownRenderer markdown={markdown} />
}
