import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, message, Space, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { TopMenuEditable } from '~/components/TopMenuEditable'
import { useDynamicContent, useDynamicContentRefresh } from '~/context'
import { useLanguange } from '~/utils/lang'
import { getTopMenu, reorderMenu } from '~/utils/location/api/endpoints'
import { useIsMounted } from '~/utils/useIsMounted'
import { MenuItem, Menus } from '~shared/api'
import { runAsync } from '~shared/utils'

interface MenuChanges {
  positionChanges: Array<{
    categoryId: number | null
    contents: Array<{ type: 'link' | 'category', id: number }>
  }>
  deletedItems: Array<{ type: 'link' | 'category', id: number }>
}

export function MenuEdit (): JSX.Element {
  const refreshDynamicContent = useDynamicContentRefresh()
  const { menus: menuInBar } = useDynamicContent()
  const [versionId, setVersionId] = useState(0)
  const [menus, setMenus] = useState<Menus>([])
  const [modifiedMenus, setModifiedMenus] = useState<Menus>([])
  const [dirty, setDirty] = useState(false)
  const [menuChanges, setMenuChanges] = useState<MenuChanges>({
    positionChanges: [],
    deletedItems: []
  })
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { currentLanguage } = useLanguange()

  useEffect(() => {
    runAsync(async () => {
      const response = await getTopMenu({
        requestBody: { lang: currentLanguage, all: true }
      })
      if (response.status === 'success') {
        setMenus(response.menus)
        setModifiedMenus(response.menus)
      } else {
        message
          .error('Failed to fetched the current menu data.')
          .then(null, console.error)
      }
    })
  }, [menuInBar, currentLanguage])

  useEffect(() => {
    const newMenuChanges: MenuChanges = {
      positionChanges: [],
      deletedItems: []
    }

    // Check if top level items have been reordered
    if (
      menus.length !== modifiedMenus.length ||
      menus.some(
        (menu, idx) =>
          menu.id !== modifiedMenus[idx].id ||
          'links' in menu !== 'links' in modifiedMenus[idx]
      )
    ) {
      newMenuChanges.positionChanges.push({
        categoryId: null,
        contents: modifiedMenus.map((menu) => ({
          id: menu.id,
          type: 'links' in menu ? 'category' : 'link'
        }))
      })
    }

    // Check if category items have been reordered
    for (const newMenu of modifiedMenus.filter(
      (menu): menu is MenuItem => 'links' in menu
    )) {
      const oldMenu = menus.find(
        (menu): menu is MenuItem => 'links' in menu && menu.id === newMenu.id
      )

      if (
        oldMenu === undefined ||
        newMenu.links.length !== oldMenu.links.length ||
        newMenu.links.some(
          (linkItem, idx) => linkItem.id !== oldMenu.links[idx].id
        )
      ) {
        newMenuChanges.positionChanges.push({
          categoryId: newMenu.id,
          contents: newMenu.links.map((item) => ({
            id: item.id,
            type: 'link'
          }))
        })
      }
    }

    const allItemsInModifiedMenus = modifiedMenus.reduce(
      (acc, x) => {
        if ('links' in x) {
          acc.categories.add(x.id)
          for (const link of x.links) {
            acc.pages.add(link.id)
          }
        } else {
          acc.pages.add(x.id)
        }
        return acc
      },
      { categories: new Set<number>(), pages: new Set<number>() }
    )

    const allItemsInMenus = menus.reduce(
      (acc, x) => {
        if ('links' in x) {
          acc.categories.push(x.id)
          for (const link of x.links) {
            acc.pages.push(link.id)
          }
        } else {
          acc.pages.push(x.id)
        }
        return acc
      },
      { categories: new Array<number>(), pages: new Array<number>() }
    )

    newMenuChanges.deletedItems.push(
      ...allItemsInMenus.categories
        .filter((x) => !allItemsInModifiedMenus.categories.has(x))
        .map((x) => ({ type: 'category' as const, id: x })),
      ...allItemsInMenus.pages
        .filter((x) => !allItemsInModifiedMenus.pages.has(x))
        .map((x) => ({ type: 'link' as const, id: x }))
    )

    setMenuChanges(newMenuChanges)
  }, [menus, modifiedMenus])

  const isMounted = useIsMounted()

  return (
    <section>
      <Typography.Title level={3}>Edit Menu/Pages</Typography.Title>
      <div style={{ minHeight: '300px' }}>
        <TopMenuEditable
          menus={menus}
          key={`version-${versionId}`}
          onChange={(newMenus) => {
            setModifiedMenus(newMenus)
            if (newMenus !== menus) setDirty(true)
          }}
          getMenuItemActions={({ onChange, itemPosition, topMenuPosition }) => {
            return (
              <>
                <EditOutlined
                  className='menu-icons'
                  onClick={() => {
                    const item =
                      itemPosition === null
                        ? modifiedMenus[topMenuPosition]
                        : (modifiedMenus[topMenuPosition] as MenuItem).links[
                            itemPosition
                          ]

                    if ('links' in item) {
                      history.push(
                        `/${currentLanguage}/admin/category/edit`,
                        item
                      )
                    } else {
                      history.push(`/${currentLanguage}/admin/page/edit`, {
                        ...item,
                        category:
                          itemPosition !== null
                            ? modifiedMenus[topMenuPosition].url
                            : ''
                      })
                    }
                  }}
                />

                <DeleteOutlined
                  className='menu-icons'
                  onClick={() => {
                    onChange((oldMenus) => {
                      const newMenus = oldMenus.slice()
                      if (itemPosition === null) {
                        if (
                          !('links' in oldMenus[topMenuPosition]) ||
                          (oldMenus[topMenuPosition] as MenuItem).links
                            .length === 0
                        ) {
                          newMenus.splice(topMenuPosition, 1)
                        } else {
                          message
                            .error(
                              'Please move or delete all links contained in this category before deleting.'
                            )
                            .then(null, console.error)

                          return oldMenus
                        }
                      } else {
                        newMenus[topMenuPosition] = {
                          ...newMenus[topMenuPosition],
                          links: [
                            ...(newMenus[topMenuPosition] as MenuItem).links
                          ]
                        };
                        (newMenus[topMenuPosition] as MenuItem).links.splice(
                          itemPosition,
                          1
                        )
                      }
                      return newMenus
                    })
                  }}
                />
              </>
            )
          }}
        />
      </div>
      <Space>
        <Button
          type='primary'
          disabled={!dirty}
          loading={loading}
          onClick={async () => {
            try {
              setLoading(true)
              const update = await reorderMenu({
                requestBody: menuChanges
              })

              if (!isMounted()) return

              if (update.status === 'success') {
                message
                  .success('Successfully updated the menu.')
                  .then(null, console.error)

                refreshDynamicContent()
                setDirty(false)
              } else {
                message
                  .error(
                    'An unexpected error occurred during the update process.'
                  )
                  .then(null, console.error)
              }
            } catch (e) {
              message
                .error(
                  'An unexpected error occurred during the update process.'
                )
                .then(null, console.error)
            } finally {
              if (isMounted()) {
                setLoading(false)
              }
            }
          }}
        >
          Save Changes
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            setVersionId(versionId + 1)
            setDirty(false)
            setModifiedMenus(menus)
          }}
        >
          Cancel
        </Button>
      </Space>
    </section>
  )
}
