import { Button, Form, Input, message, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { LinkButton } from '~/components/LinkButton'
import { SlateEditor } from '~/components/SlateEditor'
import { useDynamicContentRefresh } from '~/context'
import { useLanguange } from '~/utils/lang'
import { PAGE_BASE } from '~/utils/location'
import { createPage, getCategories } from '~/utils/location/api/endpoints'
import { useIsMounted } from '~/utils/useIsMounted'
import { runAsync } from '~shared/utils'

export function PageCreationPage (): JSX.Element {
  const [form] = Form.useForm<{
    name: string
    content: string
    url: string
    lang: 'en' | 'zh'
    category: string
  }>()

  const history = useHistory()
  const isMounted = useIsMounted()
  const refreshDynamicContent = useDynamicContentRefresh()

  useEffect(() => {
    form.validateFields().catch(console.error)
  }, [form])
  const currentLanguage = useLanguange()
  const [lang, setLang] = useState(currentLanguage.currentLanguage)
  const [categoryLink, setCategoryLink] = useState('')
  const [categories, setCategories] = useState<
  Array<{ name: string, link: string }>
  >([])
  const [submitting, setSubmitting] = useState(false)
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true)

  useEffect(() => {
    let mounted = true

    runAsync(async () => {
      setCategoriesLoading(true)

      const menuItems = await getCategories({ requestParams: { lang } })
      if (!mounted || !Array.isArray(menuItems)) return

      setCategoriesLoading(false)
      setCategories(menuItems.map((m) => ({ name: m.name, link: m.url })))
    })

    return () => {
      mounted = false
    }
  }, [lang])

  return (
    <section>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout='horizontal'
        onValuesChange={(value) => {
          if ('lang' in value) {
            setLang(value.lang)
          }
          if ('category' in value) {
            setCategoryLink(value.category)
          }
        }}
        initialValues={{
          lang,
          category: '',
          url: ''
        }}
      >
        <Form.Item
          label='Language'
          name='lang'
          required
          tooltip='The language of the page.'
        >
          <Select
            options={[
              { label: '中文', value: 'zh' },
              { label: 'English', value: 'en' }
            ]}
          />
        </Form.Item>
        <Form.Item
          label='Name'
          name='name'
          rules={[
            {
              required: true,
              message: 'Please enter a name!'
            }
          ]}
          tooltip='The name that will be shown in menus.'
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Category'
          name='category'
          required
          tooltip='The submenu that this page should be under a category in the menu.'
          shouldUpdate={(prevValues, curValues) =>
            prevValues.lang !== curValues.lang}
        >
          <Select
            loading={categoriesLoading}
            options={[
              { label: (<i>(None)</i>) as React.ReactNode, value: '' }
            ].concat(
              categories.map((c) => ({
                label: c.name,
                value: c.link
              }))
            )}
          />
        </Form.Item>
        <Form.Item
          label='Url'
          name='url'
          rules={[
            {
              validator: async (_, value): Promise<void> => {
                if (!/^[0-9a-z-]*$/.test(value)) {
                  return await Promise.reject(
                    new Error(
                      'Only lowercase alphanumerical characters and dashes "-" are allowed.'
                    )
                  )
                }
              }
            }
          ]}
          tooltip='The page name that is displayed in the url.'
        >
          <Input
            addonBefore={
              window.location.origin +
              `/${PAGE_BASE}/${lang}/${categoryLink}/`.replace(/\/+/g, '/')
            }
          />
        </Form.Item>
        <Form.Item label='Page Content' name='content' required>
          <SlateEditor />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 14, offset: 4 }}>
          <Space>
            <Button
              loading={submitting}
              htmlType='submit'
              type='primary'
              onClick={() => {
                runAsync(async () => {
                  try {
                    setSubmitting(true)
                    const values = await form.validateFields()

                    const response = await createPage({
                      requestBody: {
                        page: JSON.stringify(values.content),
                        category: values.category,
                        lang: values.lang,
                        url: values.url,
                        name: values.name
                      }
                    })

                    if (response.success) {
                      message
                        .success(response.message)
                        .then(() => {}, console.error)
                      refreshDynamicContent()
                      history.push('../')
                    } else {
                      message
                        .error(response.message)
                        .then(() => {}, console.error)
                    }
                  } catch (errorInfo) {
                    console.log('[PageCreation]', errorInfo)
                    message
                      .error(
                        'An error occurred. Please check your inputs and try again later.'
                      )
                      .then(() => {}, console.error)
                  } finally {
                    if (isMounted()) {
                      setSubmitting(false)
                    }
                  }
                })
              }}
            >
              Create Page
            </Button>
            <LinkButton to='../'>Cancel</LinkButton>
          </Space>
        </Form.Item>
      </Form>
    </section>
  )
}
