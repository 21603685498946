import { Checkbox, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useEffect, useMemo, useRef } from 'react'
import { User } from '~shared/api'

export interface UsersTableProps {
  users?: User[]
  loading?: boolean
  onChange?: (id: string, update: Partial<User>) => void
  editing?: boolean
}

export function UsersTable ({
  users,
  loading,
  editing = false,
  onChange
}: UsersTableProps): JSX.Element {
  const onChangeRef = useRef(onChange)

  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])

  const columns = useMemo<ColumnsType<User>>(
    () => [
      { title: 'Name', dataIndex: 'name' },
      { title: 'Email', dataIndex: 'email' },
      {
        title: 'Verified',
        dataIndex: 'isVerified',
        render: (value: boolean, user) =>
          editing
            ? (
              <Checkbox
                defaultChecked={value}
                onChange={(e) => {
                  onChangeRef.current?.(user.id, {
                    isVerified: e.target.checked
                  })
                }}
              />
              )
            : value
              ? (
                  'yes'
                )
              : (
                  'no'
                )
      },
      {
        title: 'Admin',
        dataIndex: 'isAdmin',
        render: (value: boolean, user) =>
          editing
            ? (
              <Checkbox
                defaultChecked={value}
                onChange={(e) => {
                  onChangeRef.current?.(user.id, { isAdmin: e.target.checked })
                }}
              />
              )
            : value
              ? (
                  'yes'
                )
              : (
                  'no'
                )
      }
    ],

    [editing]
  )

  return (
    <Table loading={loading} columns={columns} dataSource={users} rowKey='id' />
  )
}
