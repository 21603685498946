import { useLocation } from 'react-router'

export type Language = 'en' | 'zh'

/**
 * Infers the location from the current pathname.
 *
 * Example:
 * ```ts
 * const location = useLocation()
 *
 * const language = getLanguageFromUrl(location.pathname)
 * ```
 */
export function inferLanguageFromUrl (location: string): Language {
  return location.startsWith('/en') ? 'en' : 'zh'
}

interface LanguageHelper {
  currentLanguage: Language
  otherLanguage: Language
  isEnglish: boolean
  isChinese: boolean
  path: string
}

export function useLanguange (): LanguageHelper {
  const location = useLocation()

  const currentLanguage = inferLanguageFromUrl(location.pathname)
  return {
    currentLanguage,
    isChinese: currentLanguage === 'zh',
    isEnglish: currentLanguage === 'en',
    otherLanguage: currentLanguage === 'en' ? 'zh' : 'en',
    path: location.pathname.replace(/\/(en|zh)(\/|$)/, '/')
  }
}
