/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import isUrl from 'is-url'
import { Editor, Element as SlateElement, Range, Transforms } from 'slate'

import { CustomEditor, LinkElement } from '~/types/slate.custom.types'

export const withInlines = (editor: CustomEditor): CustomEditor => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = (element) =>
    ['link'].includes(element.type) || isInline(element)

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }
  return editor
}

// const insertLink = (editor: CustomEditor, url: string): void => {
//   if (editor.selection != null) {
//     wrapLink(editor, url)
//   }
// }

export const isLinkActive = (editor: CustomEditor): boolean => {
  // default search is selection, so this is testing if the selection has link elements
  // main use is to find links and unwrap them
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
  })
  return !!link
}
export const getLink = (editor: CustomEditor): string => {
  const [node] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
  })
  return (node[0] as LinkElement).url
}
export const unwrapLink = (editor: CustomEditor): void => {
  // unwrapLink at selection
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
  })
}

export const wrapLink = (editor: CustomEditor, url: string): void => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }
  const { selection } = editor
  const isCollapsed = selection != null && Range.isCollapsed(selection)
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : []
  }
  // if collapsed, insert a new link, otherwise just wrap selections
  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}
