import './App.less'
import LayoutWrapper from './layouts/LayoutWrapper'
import { Routes } from './routes'

export function App (): JSX.Element {
  return (
    /**
     * can change layout type changing the layoutType prop
     * (probably should be based on the current url)
     */
    <LayoutWrapper>
      <Routes />
    </LayoutWrapper>
  )
}
