import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { Language } from '~/utils/lang'
import { Menus } from '~shared/api'
export interface TopMenuProps {
  menus: Menus
  lang: Language
}

const TopMenu = ({ menus, lang }: TopMenuProps): JSX.Element => {
  return (
    <Menu mode='horizontal'>
      {menus.map((l) => {
        if ('links' in l) {
          return (
            <Menu.SubMenu title={l.text} key={`cat-${l.id}`}>
              {l.links.map((li) => (
                <Menu.Item key={`page-${li.id}`}>
                  <Link to={`/${lang}/${l.url}/${li.url}`}>{li.text}</Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          )
        }

        return (
          <Menu.Item key={`page-${l.id}`}>
            <Link to={`/${lang}/${l.url}`}>{l.text}</Link>
          </Menu.Item>
        )
      })}
    </Menu>
  )
}

export default TopMenu
