/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { UploadOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, Input, message, Space } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { addSermon, editSermon } from '~/utils/location/api/endpoints'
import { resolveEndpointUrl } from '~/utils/location/api/utils'
import {
  fileDownloadEndpoint,
  fileUploadEndpoint,
  FileUploadResponse
} from '~shared/api'
import { isFalsy, isTruthy } from '~shared/utils'
import { AppendableSelect } from './AppendableSelect'
import { LinkButton } from './LinkButton'

const dateFormat = 'MM/DD/YYYY'

export interface SermonAddOrEditOneProps {
  date_of_sermon: moment.Moment
  title: string
  speaker: string
  speakers: string[]
  type_of_sermon: string
  types_of_sermons: string[]
  bible_source: string
  verses: string
  verse_of_the_week: string
  id?: number
  mp3?: string
  edit: boolean
  published: boolean
}
export function SermonAddOrEditOne (info: SermonAddOrEditOneProps): JSX.Element {
  // const [uploaderPlaceholder, setUploaderPlaceholder] = useState('Click or drag mp3 file to this area to upload')

  const [fileList, setFileList] = useState<UploadFile[] | undefined>(undefined)
  const history = useHistory()

  const [form] = Form.useForm()
  useEffect(() => {
    if (isTruthy(info.mp3)) {
      setFileList([
        {
          uid: '1',
          name: 'mp3 file',
          status: 'done',
          response: 'Server ok',
          url: resolveEndpointUrl(fileDownloadEndpoint, {
            requestParams: { id: info.mp3 }
          })
        }
      ])
    }
  }, [info.mp3])

  useEffect(() => {
    form.setFieldsValue({ ...info, mp3_upload: info.mp3 })
  }, [info, form])

  return (
    <Form
      form={form}
      name='SermonAddOrEditOne'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Form.Item
        label='Date'
        name='date_of_sermon'
        rules={[{ required: true, message: 'Please enter the date!' }]}
      >
        <DatePicker format={dateFormat} />
      </Form.Item>

      <Form.Item label='Title' name='title'>
        <Input />
      </Form.Item>

      <Form.Item
        label='Speaker'
        name='speaker'
        rules={[{ required: true, message: 'Please enter the speaker!' }]}
      >
        <AppendableSelect
          showSearch
          style={{ width: '100%' }}
          initialOptions={info.speakers}
        />
      </Form.Item>

      <Form.Item
        label='Type'
        name='type_of_sermon'
        rules={[{ required: true, message: 'Please enter the type!' }]}
      >
        <AppendableSelect
          showSearch
          style={{ width: '100%' }}
          initialOptions={info.types_of_sermons}
        />
      </Form.Item>

      <Form.Item label='Bible Source' name='bible_source'>
        <Input />
      </Form.Item>

      <Form.Item label='Bible Verses' name='verses'>
        <Input.TextArea autoSize={{ minRows: 3, maxRows: 30 }} />
      </Form.Item>

      <Form.Item label='Verse of the week' name='verse_of_the_week'>
        <Input />
      </Form.Item>
      <Form.Item label='mp3 upload' name='mp3_upload'>
        <Dragger
          name={fileUploadEndpoint.fileFormName}
          accept='.mp3'
          multiple={false}
          maxCount={1}
          action={resolveEndpointUrl(fileUploadEndpoint, {})}
          onChange={(v: UploadChangeParam<UploadFile<FileUploadResponse>>) => {
            const { status, response } = v.file

            setFileList([...v.fileList])
            if (isTruthy(v.fileList) && v.fileList.length === 0) {
              // when the only file is deleted, empty the fileList
              setFileList(undefined)
              form.setFieldsValue({ mp3_upload: undefined })
            }

            if (status === 'done' && response?.id) {
              // file upload done, fileList has been taken care off above, but need to update mp3_upload
              message.success(`${v.file.name} file uploaded successfully.`)

              form.setFieldsValue({ mp3_upload: response.id })
              setFileList(
                v.fileList.map((f) => {
                  return {
                    ...f,
                    url:
                      v.file.name === f.name
                        ? resolveEndpointUrl(fileDownloadEndpoint, {
                          requestParams: { id: response.id }
                        })
                        : ''
                  }
                })
              )
            } else if (status === 'error') {
              message.error(`${v.file.name} file upload failed.`)
            }
          }}
          fileList={fileList}
          showUploadList={{
            showDownloadIcon: true,
            showRemoveIcon: true
          }}
        >
          <p className='ant-upload-drag-icon'>
            <UploadOutlined />
          </p>
          <p className='ant-upload-text'>
            Click or drag mp3 file to this area to upload
          </p>
        </Dragger>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Space>
          <Button
            type='primary'
            htmlType='submit'
            onClick={async () => {
              const fs = form.getFieldsValue()
              if (
                fs.date_of_sermon !== info.date_of_sermon ||
                fs.title !== info.title ||
                fs.speaker !== info.speaker ||
                fs.type_of_sermon !== info.type_of_sermon ||
                fs.bible_source !== info.bible_source ||
                fs.verses !== info.verses ||
                fs.verse_of_the_week !== info.verse_of_the_week ||
                fs.mp3_upload !== info.mp3 ||
                isFalsy(info.id)
              ) {
                // console.log(fs.date_of_sermon)
                const formData = {
                  date_of_sermon: fs.date_of_sermon,
                  title: fs.title ?? 'unknown',
                  speaker: fs.speaker ?? 'unknown',
                  type_of_sermon: fs.type_of_sermon ?? 'unknown',
                  bible_source: fs.bible_source ?? '',
                  verses: fs.verses ?? '',
                  verse_of_the_week: fs.verse_of_the_week ?? '',
                  mp3: fs.mp3_upload,
                  published: info.published
                }

                let result
                if (info.edit) {
                  result = await editSermon({
                    requestBody: { ...formData, id: info.id }
                  })
                } else {
                  result = await addSermon({
                    requestBody: formData
                  })
                }

                if (result.success) {
                  message.success('Success!')
                  history.goBack()
                } else {
                  message.error('Error!')
                }
              } else {
                message.warning('No change to submit!')
              }
            }}
          >
            Submit
          </Button>
          <LinkButton to='./'>Cancel</LinkButton>
        </Space>
      </Form.Item>
    </Form>
  )
}
