import { css } from '@emotion/react'
import { Button, Col, Divider, Layout, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { logo } from '~/assets'
import {
  useDynamicContent,
  useGoogleApiStatus,
  useLoginStatus
} from '~/context'
import { useLanguange } from '~/utils/lang'
import { CgcwFooter } from '../components/CgcwFooter'
import TopMenu from '../components/TopMenu'
import './DefaultLayout.less'

const { Content, Footer } = Layout

interface DefaultLayoutProps {
  children: React.ReactNode
}

const DefaultLayout = ({ children }: DefaultLayoutProps): JSX.Element => {
  const currentLanguage = useLanguange()
  const { isAdmin, loggedIn, loginId, logout } = useLoginStatus()
  const googleApiReady = useGoogleApiStatus()

  const { menus } = useDynamicContent()
  return (
    <Layout className='default-layout'>
      <div className='logo-container' style={{ background: '#1b4b98' }}>
        <img
          className='logo-image'
          src={logo}
          alt='吾思德基督福音教會 : Christian Gospel Church in Worcester'
        />
      </div>
      <div>
        <div className='language-selection-zone'>
          <div className='language-selection-text'>
            <span className='admin-link'>
              {isAdmin
                ? (
                  <Link
                    className='white-color'
                    to={`/${currentLanguage.currentLanguage}/admin/`}
                  >
                    Admin
                  </Link>
                  )
                : null}
            </span>
            <span className='admin-link'>
              {loggedIn
                ? (
                  <Button
                    type='link'
                    className='white-color'
                    css={css`
                    font-weight: 700;
                    padding: 0;
                  `}
                    onClick={() => {
                      if (googleApiReady && loggedIn) {
                        window.google.accounts.id.revoke(loginId)
                        logout()
                      }
                    }}
                  >
                    Logout
                  </Button>
                  )
                : (
                  <Link
                    className='white-color'
                    to={`/${currentLanguage.currentLanguage}/login/`}
                  >
                    Login
                  </Link>
                  )}
            </span>
            {/* <Link
              className='white-color'
              to={`/${currentLanguage.otherLanguage}/`}
            >
              {currentLanguage.otherLanguage}
            </Link> */}
          </div>
        </div>
      </div>

      <TopMenu menus={menus ?? []} lang={currentLanguage.currentLanguage} />

      <Content style={{ padding: '1em' }}>
        <Row>
          <Col lg={{ span: 20, offset: 2 }}>{children}</Col>
        </Row>
      </Content>
      <Divider className='page-divider' />
      <Footer className='footer-box'>
        <CgcwFooter />
      </Footer>
    </Layout>
  )
}

export default DefaultLayout
