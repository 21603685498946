import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch
} from 'react-router-dom'
import { useLoginStatus } from '~/context'
import { Page403, Page404 } from '~/pages'
import { AddSermon } from '~/pages/AddSermon'
import { AdminDashboard } from '~/pages/AdminDashboard'
import { CategoryCreationPage } from '~/pages/CategoryCreation'
import { CategoryEditPage } from '~/pages/CategoryEdit'
import { ContentPage } from '~/pages/Content'
import { EditSermon } from '~/pages/EditSermon'
import { Login } from '~/pages/Login'
import { PageCreationPage } from '~/pages/PageCreation'
import { PageEditPage } from '~/pages/PageEdit'
import { Sermons } from '~/pages/Sermons'
import { Users } from '~/pages/Users'
import { useLanguange } from '~/utils/lang'

function AdminRoutes (): JSX.Element {
  const currentLanguage = useLanguange()
  const { path } = useRouteMatch()
  const location = useLocation()
  const { loggedIn, isAdmin } = useLoginStatus()

  if (!loggedIn) {
    console.log(
      'Admin Pages: you are not logged in. Redirecting to login page.'
    )
    return (
      <Redirect
        to={{
          pathname: `/${currentLanguage.currentLanguage}/login`,
          state: {
            previousUrl: location.pathname
          }
        }}
      />
    )
  }

  if (!isAdmin) {
    console.log(
      'Admin pages: you are not logged in as admin. Showing Error page 403'
    )
    return <Page403 />
  }

  return (
    <Switch>
      <Route path={`${path}`} exact component={AdminDashboard} />
      <Route path={`${path}/sermon/`} exact component={Sermons} />
      <Route path={`${path}/user/`} exact component={Users} />
      <Route path={`${path}/sermon/edit`} exact component={EditSermon} />
      <Route path={`${path}/sermon/add`} exact component={AddSermon} />
      <Route path={`${path}/page/add`} exact component={PageCreationPage} />
      <Route path={`${path}/page/edit`} exact component={PageEditPage} />
      <Route
        path={`${path}/category/add`}
        exact
        component={CategoryCreationPage}
      />
      <Route
        path={`${path}/category/edit`}
        exact
        component={CategoryEditPage}
      />
      <Route component={Page404} />
    </Switch>
  )
}

function Routes (): JSX.Element {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}login`} component={Login} />
      <Route path={`${path}admin`} component={AdminRoutes} />
      <Route path={path} component={ContentPage} />
    </Switch>
  )
}

function LanguageRoutes (): JSX.Element {
  return (
    <Switch>
      <Route strict path='/:lang(en|zh)/' component={Routes} />
      <Redirect to='/zh/' />
    </Switch>
  )
}

export default LanguageRoutes
