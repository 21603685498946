import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
import {
  DynamicContentProvider,
  GoogleApiClientProvider,
  LoginProvider
} from './context'
import './index.less'
import { PAGE_BASE } from './utils/location'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={PAGE_BASE}>
      <GoogleApiClientProvider>
        <DynamicContentProvider>
          <DndProvider backend={HTML5Backend}>
            <LoginProvider>
              <App />
            </LoginProvider>
          </DndProvider>
        </DynamicContentProvider>
      </GoogleApiClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
