import { Divider, Image, Typography } from 'antd'
import { lexer, LexerToken } from 'marked'

interface MarkdownTokenRendererProps {
  token: LexerToken
}

function MarkdownTokenRenderer ({
  token
}: MarkdownTokenRendererProps): JSX.Element {
  switch (token.type) {
    case 'paragraph':
      return (
        <Typography.Paragraph>
          {token.tokens.map((t, i) => (
            <MarkdownTokenRenderer token={t as LexerToken} key={i} />
          ))}
        </Typography.Paragraph>
      )
    case 'heading':
      return (
        <Typography.Title level={Math.min(token.depth, 5) as 1 | 2 | 3 | 4 | 5}>
          {token.tokens.map((t, i) => (
            <MarkdownTokenRenderer token={t as LexerToken} key={i} />
          ))}
        </Typography.Title>
      )
    case 'hr':
      return <Divider />
    case 'link':
      return (
        <Typography.Link href={token.href}>
          {token.tokens.map((t, i) => (
            <MarkdownTokenRenderer token={t as LexerToken} key={i} />
          ))}
        </Typography.Link>
      )
    case 'strong':
      return (
        <Typography.Text strong>
          {token.tokens.map((t, i) => (
            <MarkdownTokenRenderer token={t as LexerToken} key={i} />
          ))}
        </Typography.Text>
      )
    case 'em':
      return (
        <Typography.Text italic>
          {token.tokens.map((t, i) => (
            <MarkdownTokenRenderer token={t as LexerToken} key={i} />
          ))}
        </Typography.Text>
      )
    case 'del':
      return (
        <Typography.Text delete>
          {token.tokens.map((t, i) => (
            <MarkdownTokenRenderer token={t as LexerToken} key={i} />
          ))}
        </Typography.Text>
      )
    case 'blockquote':
      return (
        <Typography.Paragraph>
          <blockquote>
            {token.tokens.map((t, i) => (
              <MarkdownTokenRenderer token={t as LexerToken} key={i} />
            ))}
          </blockquote>
        </Typography.Paragraph>
      )
    case 'code':
      return (
        <Typography.Paragraph>
          <pre>{token.text}</pre>
        </Typography.Paragraph>
      )
    case 'text':
      return (
        <>
          {
            new DOMParser().parseFromString(token.text, 'text/html')
              .documentElement.textContent
          }
        </>
      )
    case 'list': {
      const ListComponent = token.ordered ? 'ol' : ('ul' as const)
      return (
        <Typography.Paragraph>
          <ListComponent>
            {token.items.map((t, i) => (
              <MarkdownTokenRenderer token={t as LexerToken} key={i} />
            ))}
          </ListComponent>
        </Typography.Paragraph>
      )
    }
    case 'list_item':
      return (
        <li>
          {token.tokens.map((t, i) => (
            <MarkdownTokenRenderer token={t as LexerToken} key={i} />
          ))}
        </li>
      )
    case 'codespan':
      return <Typography.Text code>{token.text}</Typography.Text>
    case 'escape':
      return (
        <>
          {
            new DOMParser().parseFromString(token.text, 'text/html')
              .documentElement.textContent
          }
        </>
      )
    case 'image':
      return <Image src={token.href} alt={token.text} />
    case 'space':
      return <></>
    default:
      return <>{token.raw}</>
  }
}

export interface MarkdownRendererProps {
  markdown: string
}

export function MarkdownRenderer ({
  markdown
}: MarkdownRendererProps): JSX.Element {
  const tokens = lexer(markdown)

  return (
    <>
      {tokens.map((t, i) => (
        <MarkdownTokenRenderer token={t as LexerToken} key={i} />
      ))}
    </>
  )
}
