import { css } from '@emotion/css'
import { useEffect, useState } from 'react'
import { Descendant } from 'slate'
import { useSelected } from 'slate-react'

import {
  CustomText,
  ImageElement,
  SectionElement,
  SermonTableElement
} from '~/types/slate.custom.types'
import { getSermons } from '~/utils/location/api/endpoints'
import { useIsMounted } from '~/utils/useIsMounted'
import { Sermon } from '~shared/api'
import { isTruthy, runAsync } from '~shared/utils'
import { SermonsTable } from './SermonsTable'

export interface SlateRendererRawProps {
  value: Descendant[]
}

export function SlateRendererRaw ({
  value
}: SlateRendererRawProps): JSX.Element {
  return (
    <>
      {value.map((e, idx) => (
        <Element key={idx} element={e} />
      ))}
    </>
  )
}

const Element = ({ element }: { element: Descendant }): JSX.Element => {
  if (!('type' in element)) {
    return <Leaf {...element} />
  }

  switch (element.type) {
    case 'heading-three':
      return (
        <p className='heading-three'>
          {element.children.map((e, idx) => (
            <Element key={idx} element={e} />
          ))}
        </p>
      )
    case 'heading-four':
      return (
        <p className='heading-four'>
          {element.children.map((e, idx) =>
            'type' in e
              ? (
                <Element key={idx} element={e} />
                )
              : (
                <Leaf key={idx} {...e} />
                )
          )}
        </p>
      )
    case 'section':
      return <Section {...element} />
    case 'image':
      return <Image {...element} />
    case 'link':
      return <LinkComponent {...element} />
    case 'sermon-table':
      return <SermonTableRender {...element} />
    default:
      return (
        <p>
          {(element.children as Descendant[]).map((e, idx) => (
            <Element key={idx} element={e} />
          ))}
        </p>
      )
  }
}

const LinkComponent = ({
  children,
  url
}: {
  children: Descendant[]
  url: string
}): JSX.Element => {
  const selected = useSelected()
  console.log(url)
  console.log(children)
  return (
    <a
      href={url}
      className={
        selected
          ? css`
              box-shadow: 0 0 0 3px #ddd;
            `
          : ''
      }
    >
      {children.map((e, idx) => (
        <Element key={idx} element={e} />
      ))}
    </a>
  )
}
const SermonTableRender = (_props: SermonTableElement): JSX.Element => {
  const isMounted = useIsMounted()
  const [sermons, setSermons] = useState<Sermon[]>([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    runAsync(async () => {
      try {
        setLoading(true)
        const response = await getSermons({ requestBody: {} })
        if (!isMounted()) return
        if (response.status === 'success') {
          setSermons(response.sermons)
          setLoading(false)
        }
      } catch (e) {
        console.warn('[SermonsPage]', e)
      }
    })
  }, [isMounted])

  return <SermonsTable sermons={sermons} loading={loading} />
}

const Section = ({
  background,
  color,
  children
}: SectionElement): JSX.Element => {
  return (
    <div>
      <div
        style={{
          paddingLeft: '5px',
          backgroundColor: background,
          color: color
        }}
      >
        {children.map((e, idx) => (
          <Element key={idx} element={e} />
        ))}
      </div>
    </div>
  )
}

const Image = ({ link, url, size }: ImageElement): JSX.Element => {
  let img = (
    <img
      src={url}
      style={{
        display: 'block',
        margin: 'auto',
        width: `${size}%`,
        boxShadow: 'none'
      }}
    />
  )

  if (isTruthy(link)) {
    img = <a href={link}>{img}</a>
  }

  return <div>{img}</div>
}

const Leaf = ({
  bold = false,
  italic = false,
  text
}: CustomText): JSX.Element => {
  let children = <>{text}</>
  if (bold) {
    children = <strong>{children}</strong>
  }

  if (italic) {
    children = <em>{children}</em>
  }

  return <span>{children}</span>
}
