import { Button as AntdButton, Modal } from 'antd'
import { Dispatch, SetStateAction, useState } from 'react'
import { HexColorInput, HexColorPicker } from 'react-colorful'
import { BaseEditor, Transforms } from 'slate'
import { HistoryEditor } from 'slate-history'
import { ReactEditor, useSlateStatic } from 'slate-react'
import { ParagraphElement, SectionElement } from '../types/slate.custom.types'

// this component uses parent's bgColor and foreColor to save the state

export const InsertSectionModal = ({
  isModalVisible,
  setIsModalVisible,
  bgColor = '#FFFFFF',
  foreColor = '#000000',
  setBgColor,
  setForeColor
}: {
  isModalVisible: boolean
  setIsModalVisible: Dispatch<SetStateAction<boolean>>
  bgColor?: string
  foreColor?: string
  setBgColor: Dispatch<SetStateAction<string>>
  setForeColor: Dispatch<SetStateAction<string>>
}): JSX.Element => {
  const [oldColors] = useState({ fg: foreColor, bg: bgColor })
  const editor = useSlateStatic()
  const handleCancel = (): void => {
    setForeColor(oldColors.fg)
    setBgColor(oldColors.bg)
    setIsModalVisible(false)
  }
  const handleOk = (): void => {
    insertSection(editor, bgColor, foreColor)
    setIsModalVisible(false)
  }

  return (
    <Modal
      title='Insert or Edit Section'
      visible={isModalVisible}
      footer={[
        <AntdButton key='okModal' type='primary' onClick={handleOk}>
          Ok
        </AntdButton>,
        <AntdButton key='cancelModal' type='primary' onClick={handleCancel}>
          Cancel
        </AntdButton>
      ]}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div className='color-picker-box'>
        <div>
          Pick background color:{' '}
          <HexColorInput size={6} color={bgColor} onChange={setBgColor} />
        </div>
        <div className='color-picker-div'>
          <HexColorPicker color={bgColor} onChange={setBgColor} />
        </div>
      </div>
      <div>
        <div>
          Pick text color:
          <HexColorInput size={6} color={foreColor} onChange={setForeColor} />
        </div>
        <div className='color-picker-div'>
          <HexColorPicker color={foreColor} onChange={setForeColor} />
        </div>
      </div>
    </Modal>
  )
}
export const insertSection = (
  editor: BaseEditor & ReactEditor & HistoryEditor,
  bgColor: string,
  Color: string
): void => {
  const p: ParagraphElement = { type: 'paragraph', children: [{ text: 'p' }] }

  const section: SectionElement = {
    type: 'section',
    background: bgColor,
    color: Color,
    children: [p]
  }
  Transforms.insertNodes(editor, section)
}
