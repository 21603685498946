import {
  addSermonEndpoint,
  createCategoryEndpoint,
  createPageEndpoint,
  deleteSermonEndpoint,
  editCategoryEndpoint,
  editPageEndpoint,
  editSermonEndpoint,
  fileDownloadEndpoint,
  fileUploadEndpoint,
  getCategoriesEndpoint,
  getUsersEndpoint,
  loginEndpoint,
  loginStatusEndpoint,
  logoutEndpoint,
  menuEndpoint,
  pageContentEndpoint,
  publishSermonEndpoint,
  reorderMenuEndpoint,
  sermonsEndpoint,
  sermonSpeakersEndpoint,
  sermonTypesEndpoint,
  updateUsersEndpoint
} from '~shared/api'
import {
  createDownloadApiCall,
  createSingleFileFormApiCall,
  createStandardApiCall
} from './utils'

export const login = createStandardApiCall(loginEndpoint)
export const logout = createStandardApiCall(logoutEndpoint)
export const getLoginStatus = createStandardApiCall(loginStatusEndpoint)
export const getPageContent = createStandardApiCall(pageContentEndpoint)
export const createPage = createStandardApiCall(createPageEndpoint)
export const editPage = createStandardApiCall(editPageEndpoint)
export const editCategory = createStandardApiCall(editCategoryEndpoint)
export const createCategory = createStandardApiCall(createCategoryEndpoint)
export const getCategories = createStandardApiCall(getCategoriesEndpoint)
export const getTopMenu = createStandardApiCall(menuEndpoint)
export const uploadFile = createSingleFileFormApiCall(fileUploadEndpoint)
export const downloadFile = createDownloadApiCall(fileDownloadEndpoint)
export const reorderMenu = createStandardApiCall(reorderMenuEndpoint)
export const getSermons = createStandardApiCall(sermonsEndpoint)
export const getSermonSpeakers = createStandardApiCall(sermonSpeakersEndpoint)
export const getSermonTypes = createStandardApiCall(sermonTypesEndpoint)
export const addSermon = createStandardApiCall(addSermonEndpoint)
export const editSermon = createStandardApiCall(editSermonEndpoint)
export const publishSermon = createStandardApiCall(publishSermonEndpoint)
export const deleteSermon = createStandardApiCall(deleteSermonEndpoint)
export const getUsers = createStandardApiCall(getUsersEndpoint)
export const updateUsers = createStandardApiCall(updateUsersEndpoint)
