import {
  CloseOutlined,
  CloudDownloadOutlined,
  EditOutlined,
  PlayCircleOutlined
} from '@ant-design/icons'
import { Button, Table, Typography } from 'antd'
import { useMemo, useState } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import { Link } from 'react-router-dom'
import { resolveEndpointUrl } from '~/utils/location/api/utils'
import { fileDownloadEndpoint } from '~shared/api'
import { Sermon } from '~shared/api/content/sermons'
import { isFalsy, isTruthy } from '~shared/utils'

export interface SermonTableColumn {
  title: string
  dataIndex: string
  key: string
  render?: () => JSX.Element
}

export interface SermonsTableProps {
  sermons: Sermon[]
  loading?: boolean
  removeSermon?: (id: number) => void
  admin?: boolean
  // updateSermonPublished?: (row: number, published: boolean) => void
}

export function SermonsTable ({
  sermons,
  loading,
  // updateSermonPublished,
  admin = false,
  removeSermon
}: SermonsTableProps): JSX.Element {
  const [audioSource, setAudioSource] = useState('')
  const columns = useMemo(() => {
    const baseColumns = [
      { title: '日期', dataIndex: 'date_of_sermon', key: 'date_of_sermon' },
      { title: '主题', dataIndex: 'title', key: 'title' },
      { title: '讲员', dataIndex: 'speaker', key: 'speaker' },
      { title: '经文', dataIndex: 'bible_source', key: 'bible_source' },
      { title: '种类', dataIndex: 'type_of_sermon', key: 'type_of_sermon' },
      {
        title: '下载',
        key: 'download',
        render: (record: Sermon) => {
          return (
            <Button
              type='link'
              title='Download'
              href={
                isTruthy(record.mp3)
                  ? resolveEndpointUrl(fileDownloadEndpoint, {
                    requestParams: { id: record.mp3 }
                  })
                  : ''
              }
              shape='circle'
              download
              icon={<CloudDownloadOutlined />}
              disabled={isFalsy(record.mp3)}
            />
          )
        }
      },
      {
        title: '播放',
        key: 'play',
        render: (record: Sermon) => {
          return (
            <>
              {isTruthy(record.mp3) && (
                <Button
                  shape='circle'
                  type='link'
                  title='Play'
                  icon={<PlayCircleOutlined style={{ color: 'blue' }} />}
                  onClick={() => {
                    const url = resolveEndpointUrl(fileDownloadEndpoint, {
                      requestParams: { id: record.mp3 }
                    })
                    if (audioSource === url) {
                      setAudioSource('')
                    } else {
                      setAudioSource(url)
                    }
                  }}
                  disabled={isFalsy(record.mp3)}
                />
              )}
            </>
          )
        }
      }
    ]

    const adminColumns = [
      {
        title: 'Edit',
        key: 'edit',
        render: (record: Sermon) => {
          return (
            <Link
              to={{
                pathname: './edit',
                state: { ...record, edit: true }
              }}
            >
              <EditOutlined />
            </Link>
          )
        }
      },
      {
        title: 'Delete',
        key: 'delete',
        render: (record: Sermon) => {
          return (
            <CloseOutlined
              style={{ color: 'red' }}
              onClick={() => {
                removeSermon?.(record.id ?? -1)
              }}
            />
          )
        }
      }
    ]

    return admin ? baseColumns.concat(adminColumns) : baseColumns
  }, [admin, removeSermon, audioSource])

  return (
    <>
      {audioSource !== '' && (
        <div style={{ float: 'right' }}>
          <ReactAudioPlayer src={audioSource} autoPlay controls />
        </div>
      )}
      {/* {audioSource !== '' && (
        <audio autoPlay controls src={audioSource}>
          Play audio
        </audio>
      )} */}

      <Table
        loading={loading}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <Typography.Title level={5}>
                主题经文：{record.bible_source}
              </Typography.Title>
              <Typography.Text>{record.verses}</Typography.Text>
              <Typography.Title level={5}>本周经句</Typography.Title>
              <Typography.Text>{record.verse_of_the_week}</Typography.Text>
            </>
          ),
          rowExpandable: (record) =>
            !(record.verses === null || record.verses === '')
        }}
        dataSource={sermons}
        rowKey='id'
      />
    </>
  )
}
