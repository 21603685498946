import { css } from '@emotion/css'
import { Col, Divider, Input, InputNumber, Modal, Row, Space } from 'antd'
import { useRef } from 'react'
import { Transforms } from 'slate'
import {
  ReactEditor,
  RenderElementProps,
  useFocused,
  useSelected,
  useSlateStatic
} from 'slate-react'
import { Button, Icon } from '../components/slate.example.components'
import { ImageElement } from '../types/slate.custom.types'
const { confirm } = Modal
export const Image = ({
  attributes,
  children,
  element
}: Omit<RenderElementProps, 'element'> & {
  element: ImageElement
}): JSX.Element => {
  const imageLink = useRef(element.link)
  const imageSize = useRef(element.size)
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor, element)

  const selected = useSelected()
  const focused = useFocused()
  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        style={{
          userSelect: 'none',
          position: 'relative'
        }}
      >
        <img
          onClick={() => {
            confirm({
              icon: <></>,
              content: (
                <>
                  <div>
                    <Divider>Edit image link</Divider>
                    <p className='image-link-editor-p'>
                      Current link:
                      <span className='value-in-image-dialog'>
                        {element.link}
                      </span>
                    </p>
                    <p className='image-link-editor-p'>Enter new link below:</p>
                    <Input
                      onChange={(e) => {
                        imageLink.current = e.target.value
                      }}
                    />
                    <Divider>Edit image size</Divider>
                    <Row>
                      <Col span={18}>
                        Current Image size:{' '}
                        <span className='value-in-image-dialog'>
                          {element.size}%{' '}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Space>
                        <span>Enter new size:</span>
                        <InputNumber
                          min={1}
                          max={100}
                          onChange={(v) => {
                            imageSize.current = v
                          }}
                        />
                      </Space>
                    </Row>
                  </div>
                </>
              ),
              onOk () {
                console.log(imageLink.current)
                Transforms.setNodes(editor, {
                  link: imageLink.current,
                  size: imageSize.current
                })
                console.log()
                console.log(imageLink)
              },
              onCancel () {
                console.log('Cancel')
              }
            })
          }}
          src={element.url}
          style={{
            display: 'block',
            margin: 'auto',
            width: `${element.size}%`,
            // objectFit: 'cover',
            // width:'100%',
            // maxHeight: '20em',
            boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none'
          }}
        />

        <Button
          active
          onClick={() => Transforms.removeNodes(editor, { at: path })}
          css={css`
            position: absolute;
            top: 0.5em;
            left: 0.5em;
            background-color: white;
            cursor: pointer;
          `}
        >
          <Icon>delete</Icon>
        </Button>
      </div>
    </div>
  )
}
