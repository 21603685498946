import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'

const GoogleApiClientStatus = createContext<null | boolean>(null)
const GoogleSignInToken = createContext<null | string>(null)

export const GoogleApiClientProvider = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {
  const [tokenId, setTokenId] = useState('')
  const [clientReady, setClientReady] = useState(false)

  useEffect(() => {
    let good = true

    let timeout: number
    const callback = (): void => {
      if (!good) return

      if (window.google !== undefined) {
        window.google.accounts.id.initialize({
          auto_select: true,
          client_id: process.env.GOOGLE_CLIENT_ID,
          callback: (response) => {
            if (!good) return
            setTokenId(response.credential)
          }
        })
        setClientReady(true)
      } else {
        timeout = window.setTimeout(callback, 100)
      }
    }

    callback()

    return () => {
      good = false
      clearTimeout(timeout)
    }
  }, [])
  return (
    <GoogleSignInToken.Provider value={tokenId}>
      <GoogleApiClientStatus.Provider value={clientReady}>
        {children}
      </GoogleApiClientStatus.Provider>
    </GoogleSignInToken.Provider>
  )
}

export const useGoogleApiStatus = (): boolean => {
  const status = useContext(GoogleApiClientStatus)
  if (status !== null) return status

  console.warn(
    '[useGoogleApiStatus]',
    'WARNING: useGoogleApiStatus is not being used with GoogleApiClientProvider'
  )
  return false
}

/**
 * Gets the current login token. Returns an empty string if the user is not logged in.
 */
export const useGoogleSignInToken = (): string => {
  const token = useContext(GoogleSignInToken)
  if (token !== null) return token

  console.warn(
    '[useGoogleSignInToken]',
    'WARNING: useGoogleSignInToken is not being used with GoogleApiClientProvider'
  )
  return ''
}
